import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { useAuth } from "../../Context";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [new_password_confirmation, SetNew_password_confirmation] =
    useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");
    apiClient
      .post(
        "/users/ChangePassword",
        {
          old_password,
          new_password,
          new_password_confirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Password changed successfully") {
          toast.success("Password changed successfully");

          setTimeout(() => {
            navigate("/profile");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="flex mt-36 lg:mx-24 sm:mx-2 sm:gap-2 lg:gap-5 2xl:mx-[200px]">
        <div className="bg-[#FFEABB] h-[70vh] w-[250px] flex flex-col justify-between items-center pt-2">
          <div>
            {" "}
            <h3 className=" font-semibold lg:text-2xl text-[#212120] pb-3">
              <Link to={"/profile-update"}>Profile</Link>
            </h3>
            <p className="text-[#F3B734] font-semibold lg:text-2xl">
              <Link to={"/profile"}>Account</Link>
            </p>
          </div>

          <div className="pb-3">
            <p className="font-semibold lg:text-2xl text-[#212120] bg-[#F3B734] py-2 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a] ">
              <Link className="getstarted" onClick={handleLogout}>
                Log Out
              </Link>
            </p>
          </div>
        </div>
        <div className="bg-[#F5F5F5] sm:w-full lg:h-[70vh] lg:w-[70vw] p-4  lg:p-6">
          <div className="form">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col lg:w-[60%] space-y-4"
            >
              <div className="form-group">
                <label htmlFor="old_password" className="form-label">
                  Old Password
                </label>
                <input
                  type="password"
                  value={old_password}
                  onChange={(e) => setOld_password(e.target.value)}
                  placeholder="Old Password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="new_password" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  value={new_password}
                  onChange={(e) => setNew_password(e.target.value)}
                  placeholder="New Password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="new_password_confirmation"
                  className="form-label"
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={new_password_confirmation}
                  onChange={(e) => SetNew_password_confirmation(e.target.value)}
                  placeholder="Confirm New Password"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#F3B734] hover:bg-[#d29f2a] px-4 py-2.5 font-semibold rounded-md"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
