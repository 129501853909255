import React from "react";

const WhyMoveWithUs = () => {
  return (
    <section className="bg-[#F7DEA8] px-5 lg:px-[160px] pt-9 pb-20">
      <h2 className="text-center text-[#A27A23] font-semibold text-2xl pb-2 ">
        Why Move with Us?
      </h2>
      <div className="pb-8">
        <h3 className="text-center text-[#212120] font-semibold lg:text-[40px] leading-[24px] lg:leading-[48.86px] max-w-[510px] mx-auto">
          We don't Just Move Boxes,
        </h3>
        <h3 className="text-center text-[#212120] font-semibold lg:text-[40px] leading-[24px] lg:leading-[48.86px] max-w-[700px] mx-auto">
          We Move Memories and Dreams
        </h3>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center gap-6">
        <div className="w-[278px] h-[232px] bg-white rounded-lg px-8 py-6 why">
          <p className=" pb-3 text-center text-xl font-medium text-[#212120] ">
            Reliable
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            We have set systems in place to make our services reliable and
            tailored to serve you best.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-white rounded-lg px-8 py-6 why">
          <p className=" pb-3 text-center text-xl font-medium text-[#212120] ">
            Expertise
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            With years of experience, our team possesses the knowledge and
            skills to handle any logistical challenge effectively.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-white rounded-lg px-8 py-6 why">
          <p className=" pb-3 text-center text-xl font-medium text-[#212120] ">
            Seamless Operations
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            From start to finish, we guarantee a smooth and efficient process,
            ensuring your goods reach their destination safely and on time.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-white rounded-lg px-8 py-6 why">
          <p className=" pb-3 text-center text-xl font-medium text-[#212120] ">
            24/7 Customer Care
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            Your satisfaction is our priority. We go above and beyond to ensure
            your needs are met and expectations exceeded.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyMoveWithUs;
