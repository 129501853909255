import React from "react";
import * as Components from "../../Components";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import BookAnAppointment from "../../Components/appointment/bookAnAppointment";

export default function About() {
  return (
    <div className="app">
      <Header />
      <div className="section">
        <Components.About />
        <Components.WhyUs />
        <div className="pb-20">
          {" "}
          <BookAnAppointment />
        </div>

        <Components.StickyBar />
      </div>
      <Footer />
    </div>
  );
}
