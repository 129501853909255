import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Components from "../../Components";
import BookAnAppointment from "../appointment/bookAnAppointment";

export const Partner = () => {
  return (
    <section className="">
      <section
        className={`h-[400px] flex flex-col lg:flex-row bg-no-repeat bg-cover lg:h-[594px]  bg-[linear-gradient(to_right,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('/public/assets/img/partner.png')] w-screen overflow-x-hidden page-enter page-enter-active 
          `}
      >
        <div className="flex flex-col items-center justify-center mx-auto pt-2 sm:px-5 lg:px-0">
          <h2 className="my-3 text-[#F3B734] font-semibold sm:text-2xl lg:text-4xl max-w-[650px]">
            Join Our Dynamic Team of Packers and Movers
          </h2>
          <div className="text-[#F5F5F5] max-w-[592px] font-semibold text-sm">
            <p>
              Join our dedicated team and become an integral part of delivering
              exceptional service to our clients. At JVT Logistics, we don't
              just move boxes — we move lives, memories, and dreams.
            </p>
          </div>
          <div className="pt-10 flex sm:flex-col gap-3 lg:flex-row lg:gap-5">
            <a
              href="/move"
              className=" py-3 w-[200px] bg-[#F5F5F5] hover:bg-transparent hover:border hover:border-white hover:text-white rounded-[4px] "
            >
              Move for Us
            </a>
            <a
              href="/pack"
              className="py-3 hover:bg-[#F5F5F5] border border-white text-[#f5f5f5] rounded-[4px] w-[200px] hover:text-black"
            >
              Become a Packer
            </a>
          </div>
        </div>
      </section>

      <Components.WhyUs />
      <div className="pb-20">
        {" "}
        <BookAnAppointment />
      </div>
    </section>
  );
};
