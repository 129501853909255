import React from "react";

const RelocationHeroSection = () => {
  return (
    <section className="sm:px-5 lg:px-[160px] pt-24  lg:pt-12 pb-12 flex flex-col lg:flex-row gap-10 lg:gap-[117px] w-full lg:justify-center">
      <div className="lg:w-[30%] flex flex-col sm:text-center md:text-left lg:justify-center">
        <h2 className="text-[#A27A23] font-semibold text-4xl pb-3">
          Relocation
        </h2>
        <p className="text-[#343432] font-semibold text-sm pb-8 lg:text-justify">
          Moving within your neighborhood or across the state? Our relocation
          services have you covered. From local home moves to office relocations
          and inter-state transitions, our expert team ensures a seamless
          experience. We take care of everything from packing to transport,
          making your move stress-free.
        </p>
        <div className="sm:mt-5 sm:flex sm:justify-center lg:justify-normal">
          {" "}
          <a
            href="#relocation-services"
            className="bg-[#F3B734] hover:bg-[#a27a23] rounded-md py-3 w-[240px] text-[#212120] font-semibold text-xl text-center "
          >
            Let’s Help You Move
          </a>
        </div>
      </div>
      <div className="">
        <img src="./assets/img/relocation.png" alt="relocation-hero-section" />
      </div>
    </section>
  );
};

export default RelocationHeroSection;
