import React from "react";

export const Service = () => {
  return (
    <section className="bg-[#E9E7E2] sm:px-3 w-full flex flex-col py-[67px]">
      <h4 className=" text-base font-bold text-[#F3B734] mb-1">SERVICES</h4>
      <h4 className=" text-4xl font-medium text-[#212120] mb-3">
        Our Services
      </h4>
      <div className="flex flex-col lg:flex-row gap-6 justify-center mx-auto ">
        <div className="bg-[#FFFFFF] p-6 max-w-[460px] rounded-lg">
          <div className="flex gap-3 text-left mb-6 items-center ">
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15L4.5 1M17.5 15L14.5 1M9.5 8V6M9.5 3V1"
                stroke="#F3B734"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <h2 className=" font-medium text-[28px] leading-[33.96px] text-[#212120]">
              Relocation Services
            </h2>
          </div>
          <p className=" text-[#343432] font-semibold text-sm text-justify">
            Moving within your neighborhood or across the state? Our relocation
            services have you covered. From local home moves to office
            relocations and inter-state transitions, our expert team ensures a
            seamless experience. We take care of everything from packing to
            transport, making your move stress-free.
          </p>
          <div className="text-left mt-7">
            {" "}
            <a
              href="/relocation"
              className="text-xl font-semibold text-[#F3B734] hover:text-[#a27a23]"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="bg-[#F3B734] p-6 max-w-[455px] rounded-lg">
          <div className="flex gap-3 text-left mb-6 items-center ">
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 15L4.5 1M17.5 15L14.5 1M9.5 8V6M9.5 3V1"
                stroke="#F5F5F5"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
            <h2 className=" font-medium text-[28px] leading-[33.96px] text-[#212120]">
              Delivery Services
            </h2>
          </div>
          <p className=" text-[#343432] font-semibold text-sm text-justify">
            Need something delivered right away, scheduled for later, or sent to
            multiple locations? Our delivery services have you covered. Choose
            'Deliver Now' for urgent deliveries, 'Scheduled Delivery' to plan
            ahead, or 'Bulk Delivery' to reach several destinations in one go.
            We're here to make your deliveries simple and stress-free, every
            time.
          </p>
          <div className="text-left mt-7">
            {" "}
            <a
              href="/delivery"
              className="text-xl font-semibold text-[#F5F5F5] hover:text-[#a27a23]"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
