import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //   withCredentials: true,
  //   headers: { "Access-Control-Allow-Origin": "*" },
  // Access-Control-Allow-Credentials": true,
  // "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  // "http-equiv": "Content-Security-Policy",
  // content: "upgrade-insecure-requests",
});

export default apiClient;
