import React from "react";

const BookAnAppointment = () => {
  return (
    <section className="bg-[#A27003] flex sm:flex-col sm:gap-2 sm:justify-center sm:items-center lg:flex-row lg:justify-between sm:px-2 py-[40px]  lg:px-36 2xl:px-[298px]">
      <div>
        <p className=" uppercase text-[#FFF2D4] sm:text-sm lg:max-w-[430px] lg:text-2xl font-medium sm:text-center lg:text-left">
          WANT to book an appointment for consultation?
        </p>
      </div>
      <div className="flex items-center">
        <a
          href=""
          className="bg-[#FFFFFF] sm:px-3 py-2.5 lg:px-4 rounded-md hover:bg-[#ecc46f]"
        >
          <span className=" text-[#212120] sm:text-xs lg:text-sm font-semibold ">
            <a href="tel:+2349067970283"> Contact Us</a>
          </span>
        </a>
      </div>
    </section>
  );
};

export default BookAnAppointment;
