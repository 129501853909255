import React, { useEffect } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import OurDeliveryServices from "../../Components/Delivery/OurDeliveryServices";
import GotBookingIssues from "../../Components/relocation/GotBookingIssues";
import WhyMoveWithUs from "../../Components/Delivery/WhyMoveWithUs";
import WhatOurCustomersSay from "../../Components/Delivery/WhatOurCustomersSay";
import DeliveryHeroSection from "../../Components/Delivery/DeliveryHeroSection";

const Delivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="h-full w-full">
      <section className="lg:mb-28">
        <Header />
      </section>
      <DeliveryHeroSection />
      <OurDeliveryServices />
      <WhyMoveWithUs />
      <WhatOurCustomersSay />
      <GotBookingIssues />
      <Footer />
    </div>
  );
};

export default Delivery;
