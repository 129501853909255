import Header from "../Components/shared/header/Header";
import { useEffect } from "react";
import Footer from "../Components/shared/footer/Footer";
import "./Terms.css";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="app">
      <Header />
      <div className="section">
        <div className="container Terms">
          <h1>Terms & Conditions</h1>
          <div className="Text">
            <h2>Terms and Conditions of JVT Logistics</h2>
            <p className="">
              These Terms and Conditions ("Terms") govern your use of the
              relocation services provided by JVT Logistics ("Company", "we",
              "us", or "our"). By using our services, you agree to comply with
              these Terms. Please read them carefully before accessing or using
              our services.
            </p>
            <ol className="ol">
              <li className="li">
                Scope of Services
                <ol className="ol">
                  <li className="li">
                    JVT Logistics offers a variety of relocation services,
                    including but not limited to interstate moves, local moves,
                    office relocations, and day-to-day delivery solutions
                  </li>
                  <li className="li">
                    Our services are available to individuals and businesses
                    relocating within the state
                  </li>
                </ol>
              </li>
              <li className="li">
                Booking and Payment
                <ol className="ol">
                  <li className="li">
                    To book our services, you must provide accurate information
                    regarding your relocation needs, including the origin and
                    destination addresses, the type and quantity of items to be
                    relocated, and any additional services required.
                  </li>
                  <li className="li">
                    Upon booking, you agree to pay the quoted price for the
                    services requested. Payment must be made in full prior to
                    the commencement of the relocation.
                  </li>
                  <li className="li">
                    We accept various forms of payment, including credit/debit
                    cards, bank transfers, and cash. Payment details will be
                    provided upon booking.
                  </li>
                </ol>
              </li>
              <li className="li">
                Cancellation and Refunds
                <ol className="ol">
                  <li className="li">
                    If you need to cancel your booking, please notify us as soon
                    as possible. Cancellations made within 3 days of the
                    scheduled relocation date may be subject to a cancellation
                    fee.
                  </li>
                  <li className="li">
                    Refunds may be issued for cancellations made in accordance
                    with our cancellation policy. Refunds will be processed
                    using the original payment method and may take up to 7
                    business days to appear in your account.
                  </li>
                </ol>
              </li>
              <li className="li">
                Responsibilities of the Customer
                <ol className="ol">
                  <li className="li">
                    You are responsible for providing accurate and up-to-date
                    information regarding your relocation needs.
                  </li>
                  <li className="li">
                    You must ensure that your belongings are properly packed and
                    prepared for transportation. We are not liable for any
                    damage to items that are improperly packed or prepared.
                  </li>
                  <li className="li">
                    You must provide access to both the origin and destination
                    locations at the scheduled times agreed upon. Failure to do
                    so may result in additional charges.
                  </li>
                </ol>
              </li>
              <li className="li">
                Liability
                <ol className="ol">
                  <li className="li">
                    While we take all necessary precautions to ensure the safety
                    and security of your belongings, we cannot be held liable
                    for any loss or damage that occurs during the relocation
                    process, unless caused by our negligence.
                  </li>
                  <li className="li">
                    Our liability for any loss or damage is limited to the value
                    of the items being relocated, as declared by you prior to
                    the commencement of the relocation.
                  </li>
                </ol>
              </li>
              <li className="li">
                Insurance
                <ol className="ol">
                  <li className="li">
                    We offer insurance options to provide coverage for your
                    belongings during the relocation process. Insurance coverage
                    must be arranged and paid for by you prior to the
                    commencement of the relocation.
                  </li>
                </ol>
              </li>
              <li className="li">
                Indemnification
                <ol className="ol">
                  <li className="li">
                    You agree to indemnify and hold harmless JVT Logistics, its
                    employees, agents, and affiliates, from any claims, damages,
                    losses, liabilities, costs, or expenses arising out of or
                    related to your use of our services.
                  </li>
                </ol>
              </li>
              <li className="li">
                Amendments
                <ol className="ol">
                  <li className="li">
                    We reserve the right to amend these Terms at any time
                    without prior notice. Any changes will be effective
                    immediately upon posting on our website. It is your
                    responsibility to review these Terms periodically for
                    updates.
                  </li>
                </ol>
              </li>
              <li className="li">
                Governing Law
                <ol className="ol">
                  <li className="li">
                    These Terms are governed by and construed in accordance with
                    the laws of Lagos State Nigeria. Any disputes arising out of
                    or related to these Terms shall be subject to the exclusive
                    jurisdiction of the courts located in Nigeria.
                  </li>
                </ol>
              </li>
              <li className="li">
                Contact Information
                <ol className="ol">
                  <li className="li">
                    If you have any questions or concerns about these Terms,
                    please contact us at{" "}
                    <span mailto="support@jvtlogistics.com">
                      Support@jvtlogistics.com
                    </span>{" "}
                    or +2347042952419.
                  </li>
                </ol>
              </li>
            </ol>
            <p>I agree to the Terms & Conditions of JVT Logistics</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
