import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, ProgressBar } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiClient from "../../api/api";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import * as Components from "../../Components";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useDropzone } from "react-dropzone";

export const LocalQuote = () => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filePreviews, setFilePreviews] = useState([]);
  const [files, setFiles] = useState([]);

  const handleNext = (event) => {
    event.preventDefault();

    // Validation logic for current step
    // if (selectedIndex === 0) {
    //   if (!formData.moving_from_address) {
    //     setErrors({ ...errors, address: "Please fill in both addresses." });
    //     return;
    //   }
    //   if (!formData.moving_from_apartment_type) {
    //     setErrors({ ...errors, aptType: "Please select apartment types." });
    //     return;
    //   }
    // }

    // Move to the next step if no validation errors

    setSelectedIndex(selectedIndex + 1);
  };

  const handlePrevious = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleServiceChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      Services: {
        ...formData.Services,
        [id]: checked,
      },
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    let validFiles = [];
    let fileSizeErrors = [];

    if (files.length > 0) {
      const file = files[0];
      if (file.size > 1 * 1024 * 1024) {
        fileSizeErrors.push(`File ${file.name} exceeds 1MB.`);
      } else {
        validFiles.push(file);
      }
    }

    if (fileSizeErrors.length > 0) {
      toast.error(fileSizeErrors.join(" "));
      setErrors({ ...errors, files: fileSizeErrors });
    } else {
      setFormData({ ...formData, files: validFiles });
      setErrors({ ...errors, files: "" });
    }

    console.log(validFiles[0]);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filePreviewURLs = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles(acceptedFiles);
      setFilePreviews(filePreviewURLs);
      setErrors({ ...errors, files: "" });
    },
    [errors]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
    maxSize: 1048576, // 1MB file limit
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");

    // Convert selected services to a comma-separated string
    const selectedServices = Object.keys(formData.Services || {})
      .filter((service) => formData.Services[service])
      .join(", ");

    const dataToSubmit = new FormData();
    dataToSubmit.append("moving_from_address", formData.moving_from_address);
    dataToSubmit.append("moving_to_address", formData.moving_to_address);
    dataToSubmit.append(
      "moving_from_apartment_type",
      formData.moving_from_apartment_type
    );
    dataToSubmit.append(
      "moving_to_apartment_type",
      formData.moving_to_apartment_type
    );
    dataToSubmit.append(
      "number_of_room_moving_from",
      formData.number_of_room_moving_from
    );
    dataToSubmit.append(
      "number_of_room_moving_to",
      formData.number_of_room_moving_to
    );
    dataToSubmit.append("aptFloor", formData.aptFloor);
    dataToSubmit.append("toAptFloor", formData.toAptFloor);
    dataToSubmit.append("full_name", formData.full_name);
    // dataToSubmit.append("email", formData.email);
    dataToSubmit.append("mobile_phone", formData.mobile_phone);
    dataToSubmit.append("Services", selectedServices);
    dataToSubmit.append("moving_date", formData.moving_date);
    dataToSubmit.append("date_of_inspection", formData.date_of_inspection);
    dataToSubmit.append("description", formData.description);

    // Add files if any
    if (files.length > 0) {
      dataToSubmit.append("file", files[0]);
    }

    if (formData.email) {
      dataToSubmit.append("email", formData.email);
    }

    try {
      const response = await apiClient.post(
        "users/Local-service",
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Local service created successfully");
      setTimeout(() => {
        setIsLoading(false);
        navigate("/thank-you");
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        Object.keys(errorData).forEach((key) => {
          errorData[key].forEach((message) => {
            toast.error(message);
          });
        });
      } else {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      }
      toast.error("An error occurred while submitting the quote.");
    }
  };

  return (
    <>
      <Header />
      <section className=" px-3 lg:px-0 lg:mx-32 mt-40 2xl:mx-[280px]">
        <ToastContainer />
        <div className="">
          <h1 className=" text-[#F3B734] font-semibold text-4xl pb-3">
            Get A Quote
          </h1>

          <p className=" text-[#212120] font-normal text-base">
            Begin your relocation with us...
          </p>
        </div>
        <TabGroup
          manual
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <TabList className="hidden lg:flex justify-between lg:py-14 text-base text-black font-normal">
            <Tab
              className={({ selected }) =>
                `border border-[#8E8E8E] rounded-[4px] px-6 ${
                  selected
                    ? "!border-t-0 !border-x-0 !border-b-[4px] rounded-none !border-[#A27A23] py-3"
                    : "py-3"
                }`
              }
            >
              Current Location
            </Tab>
            <Tab
              className={({ selected }) =>
                `border border-[#8E8E8E] rounded-[4px] px-6 ${
                  selected
                    ? "!border-t-0 !border-x-0 !border-b-[4px] rounded-none !border-[#A27A23] py-3"
                    : "py-3"
                }`
              }
            >
              Destination
            </Tab>
            <Tab
              className={({ selected }) =>
                `border border-[#8E8E8E] rounded-[4px] px-6 ${
                  selected
                    ? "!border-t-0 !border-x-0 !border-b-[4px] rounded-none !border-[#A27A23] py-3"
                    : "py-3"
                }`
              }
            >
              Contact Details
            </Tab>
            <Tab
              className={({ selected }) =>
                `border border-[#8E8E8E] rounded-[4px] px-6 ${
                  selected
                    ? "!border-t-0 !border-x-0 !border-b-[4px] rounded-none !border-[#A27A23] py-3"
                    : "py-3"
                }`
              }
            >
              Upload Media
            </Tab>
            <Tab
              className={({ selected }) =>
                `border border-[#8E8E8E] rounded-[4px] px-6 ${
                  selected
                    ? "!border-t-0 !border-x-0 !border-b-[4px] rounded-none !border-[#A27A23] py-3"
                    : "py-3"
                }`
              }
            >
              Additional Information
            </Tab>
          </TabList>
          <Form
            className="max-w-[600px] sm:pt-5 lg:pt-0 "
            onSubmit={handleSubmit}
          >
            <TabPanels>
              <TabPanel>
                <p className="text-black font-normal text-base pb-3">
                  Please enter the details of your current location, including
                  your address and any relevant information.{" "}
                  <span className=" font-semibold text-sm">
                    Fields with <span className="text-red-600">*</span> are
                    required
                  </span>
                </p>{" "}
                <div className="col formColl space-y-3">
                  <Form.Group className="form-group">
                    <Form.Label>
                      Address<span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="moving_from_address"
                      value={formData.moving_from_address || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    />
                    {errors.moving_to_address && (
                      <Form.Text className="text-danger">
                        {errors.moving_from_address}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Apartment/Office type
                      <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="moving_from_apartment_type"
                      value={formData.moving_from_apartment_type || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="Storey Building">Storey Building</option>
                      <option value="Duplex">Duplex</option>
                      <option value="Terrace">Terrace</option>
                      <option value="Bungalow">Bungalow</option>
                      <option value="Mini flat">Mini flat</option>
                      <option value="Penthouse">Penthouse</option>
                      <option value="Apartment">Apartment</option>
                    </Form.Select>
                    {errors.moving_from_apartment_type && (
                      <Form.Text className="text-danger">
                        {errors.moving_from_apartment_type}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Floor <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="aptFloor"
                      value={formData.aptFloor || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="Ground">Ground</option>
                      <option value="1st">1st</option>
                      <option value="2nd">2nd</option>
                      <option value="3rd">3rd</option>
                    </Form.Select>
                    {errors.aptFloor && (
                      <Form.Text className="text-danger">
                        {errors.aptFloor}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Number of room(s) <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="number_of_room_moving_from"
                      value={formData.number_of_room_moving_from || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Select>
                    {errors.number_of_room_moving_from && (
                      <Form.Text className="text-danger">
                        {errors.number_of_room_moving_from}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </TabPanel>
              <TabPanel>
                <p className="text-black font-normal text-base pb-3">
                  Please enter the details of your new location, including your
                  address and any relevant information.{" "}
                  <span className=" font-semibold text-sm">
                    Fields with <span className="text-red-600">*</span> are
                    required
                  </span>
                </p>{" "}
                <div className="col formColl space-y-3">
                  <Form.Group className="form-group">
                    <Form.Label>
                      Address<span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="moving_to_address"
                      value={formData.moving_to_address || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    />
                    {errors.moving_to_address && (
                      <Form.Text className="text-danger">
                        {errors.moving_to_address}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Apartment/Office type
                      <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="moving_to_apartment_type"
                      value={formData.moving_to_apartment_type || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="Storey Building">Storey Building</option>
                      <option value="Duplex">Duplex</option>
                      <option value="Terrace">Terrace</option>
                      <option value="Bungalow">Bungalow</option>
                      <option value="Mini flat">Mini flat</option>
                      <option value="Penthouse">Penthouse</option>
                      <option value="Apartment">Apartment</option>
                    </Form.Select>
                    {errors.moving_to_apartment_type && (
                      <Form.Text className="text-danger">
                        {errors.moving_to_apartment_type}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Floor <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="toAptFloor"
                      value={formData.toAptFloor || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="Ground">Ground</option>
                      <option value="1st">1st</option>
                      <option value="2nd">2nd</option>
                      <option value="3rd">3rd</option>
                    </Form.Select>
                    {errors.toAptFloor && (
                      <Form.Text className="text-danger">
                        {errors.toAptFloor}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>
                      Number of room(s) <span className="text-red-600">*</span>
                    </Form.Label>
                    <Form.Select
                      name="number_of_room_moving_to"
                      value={formData.number_of_room_moving_to || ""}
                      onChange={handleInputChange}
                      className="py-2.5 rounded-[4px] border-[#696969]"
                    >
                      <option>Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </Form.Select>
                    {errors.number_of_room_moving_to && (
                      <Form.Text className="text-danger">
                        {errors.number_of_room_moving_to}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </TabPanel>

              <TabPanel>
                <p className="text-black font-normal text-base pb-3">
                  Please provide the contact details of the person who will be
                  assisting with the relocation or receiving at your
                  destination. If you are handling the relocation yourself, you
                  may skip this section.
                </p>{" "}
                <div className="row">
                  <div className=" formColl ">
                    <Form.Group className="form-group mb-2">
                      <Form.Label>
                        Full name <span></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="full_name"
                        value={formData.full_name || ""}
                        onChange={handleInputChange}
                        className="py-2.5 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                    <Form.Group className="form-group mb-2">
                      <Form.Label>
                        Email <span></span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={formData.email || ""}
                        onChange={handleInputChange}
                        className="py-2.5 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>
                        Mobile number <span></span>
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        name="mobile_phone"
                        value={formData.mobile_phone || ""}
                        onChange={handleInputChange}
                        className="py-2.5 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <p className="text-black font-normal text-base pb-3">
                  To help us assess your move, please upload a video or image of
                  your current apartment for an initial online inspection. A
                  physical inspection will also be conducted to ensure a smooth
                  and efficient relocation.
                </p>
                <div
                  {...getRootProps({
                    className: `dropzone px-[40px] py-[100px] cursor-pointer border-dashed border-2 rounded ${
                      isDragActive ? "border-gray-300" : "border-[#F3B734]"
                    }`,
                  })}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>
                      Drag 'n' drop some files here, or click to select files
                      (Max size: 1MB)
                    </p>
                  )}
                </div>
                {filePreviews.length > 0 && (
                  <div className="mt-3">
                    <p>Preview:</p>
                    {filePreviews.map((file) => (
                      <img
                        key={file.name}
                        src={file.preview}
                        alt={file.name}
                        className="w-24 h-24 object-cover rounded"
                      />
                    ))}
                  </div>
                )}
                {errors.files && <p className="text-red-600">{errors.files}</p>}
              </TabPanel>

              <TabPanel>
                <div className="row">
                  <div className=" formColl">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Select a service <span className="text-red-600">*</span>
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="packing_and_loading"
                        label="Packing and Loading"
                        onChange={handleServiceChange}
                        className=" rounded-[4px] border-[#696969] flex gap-3 my-2"
                      />
                      <Form.Check
                        type="checkbox"
                        id="unpacking_and_arranging"
                        label="Unpacking and Arranging"
                        onChange={handleServiceChange}
                        className=" rounded-[4px] border-[#696969] flex gap-3 my-2"
                      />
                      <Form.Check
                        type="checkbox"
                        id="just_moving"
                        label="Just Moving"
                        onChange={handleServiceChange}
                        className=" rounded-[4px] border-[#696969] flex gap-3 my-2"
                      />
                    </Form.Group>
                    <Form.Group className="form-group mb-2">
                      <Form.Label>
                        Choose Inspection date{" "}
                        <span className="text-red-600">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="date_of_inspection"
                        value={formData.date_of_inspection || ""}
                        onChange={handleInputChange}
                        className="py-2.5 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                    <Form.Group className="form-group mb-2">
                      <Form.Label>
                        Choose Relocation date{" "}
                        <span className="text-red-600">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="moving_date"
                        value={formData.moving_date || ""}
                        onChange={handleInputChange}
                        className="py-2.5 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                  </div>

                  <div className=" formColl">
                    <Form.Group className="form-group mb-2">
                      <Form.Label>Additional information</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={formData.description || ""}
                        onChange={handleInputChange}
                        className="py-3 rounded-[4px] border-[#696969]"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        id="termsAndConditions"
                        label="I agree to the Terms & Conditions of JVT Logistics"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            termsAccepted: e.target.checked,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
              </TabPanel>

              <div
                className={`pt-5 flex buttons ${
                  selectedIndex > 0 ? "justify-between" : "justify-end"
                }`}
              >
                {selectedIndex > 0 && (
                  <p
                    className=" flex justify-center items-center font-semibold text-lg cursor-pointer  text-black"
                    onClick={handlePrevious}
                  >
                    Back
                  </p>
                )}
                {selectedIndex < 4 ? (
                  <Button
                    className="bg-[#F3B734] px-20 py-3 text-black hover:bg-[#efbf55]"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    className=" bg-[#F3B734] px-20 py-3 text-black hover:bg-[#efbf55]"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </Button>
                )}
              </div>
            </TabPanels>
          </Form>
        </TabGroup>

        <Components.StickyBar />
      </section>
      <Footer />
    </>
  );
};
