import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, ProgressBar } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiClient from "../../api/api";

export const Quote = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = (event) => {
    event.preventDefault();
    if (step === 1) {
      if (!formData.moving_from_address) {
        setErrors({ ...errors, address: "Please fill in your address." });
        return;
      }
      if (!formData.moving_to_address) {
        setErrors({ ...errors, address: "Please fill in your address." });
        return;
      }
      if (!formData.moving_from_apartment_type) {
        setErrors({ ...errors, aptType: "Select apartment type." });
        return;
      }
      if (!formData.moving_to_apartment_type) {
        setErrors({ ...errors, aptType: "Select apartment type." });
        return;
      }
      if (!formData.number_of_room_moving_from) {
        setErrors({ ...errors, roomNumber: "Select number of rooms." });
        return;
      }
      if (!formData.number_of_room_moving_to) {
        setErrors({ ...errors, roomNumber: "Select number of rooms." });
        return;
      }
      if (!formData.aptFloor) {
        setErrors({ ...errors, aptFloor: "Select apartment floor." });
        return;
      }
    }
    if (step === 2) {
      if (!formData.full_name) {
        setErrors({ ...errors, name: "Please fill in your name." });
        return;
      }
      if (!formData.email) {
        setErrors({ ...errors, email: "Please fill in your email." });
        return;
      }
      if (!formData.mobile_phone) {
        setErrors({
          ...errors,
          telephone: "Please fill in your mobile number.",
        });
        return;
      }
    }
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleServiceChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      Services: {
        ...formData.Services,
        [id]: checked,
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const authToken = localStorage.getItem("authToken");

    // Convert selected services to a comma-separated string
    const selectedServices = Object.keys(formData.Services || {})
      .filter((service) => formData.Services[service])
      .join(", ");

    const dataToSubmit = {
      ...formData,
      Services: selectedServices,
    };

    try {
      const response = await apiClient.post(
        "users/state-service",
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("State service created successfully");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        Object.keys(errorData).forEach((key) => {
          errorData[key].forEach((message) => {
            toast.error(message);
          });
        });
      } else {
        setIsLoading(false);
        console.error(error);
        toast.error("An error occurred. Please try again.");
      }
      toast.error("An error occurred while submitting the quote.");
    }
  };

  return (
    <section className="container Quote">
      <ToastContainer />
      <div className="text">
        <h1>Get A Quote</h1>
        <div className="line"></div>
        <p>Begin your move with us...</p>
      </div>
      <Form className="second" onSubmit={handleSubmit}>
        <div className="row progressrow">
          <div className="col-4">
            <ProgressBar striped animated now={(step / 3) * 100} />
          </div>
        </div>
        {step === 1 && (
          <div className="row">
            <div className="col formColl">
              <Form.Group className="form-group">
                <Form.Label>
                  Moving from address <span></span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="moving_from_address"
                  value={formData.moving_from_address || ""}
                  onChange={handleInputChange}
                />
                {errors.moving_from_address && (
                  <Form.Text className="text-danger">
                    {errors.moving_from_address}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Moving from apartment type <span></span>
                </Form.Label>
                <Form.Select
                  name="moving_from_apartment_type"
                  value={formData.moving_from_apartment_type || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="Studio">Studio</option>
                  <option value="1 Bedroom">1 Bedroom</option>
                  <option value="2 Bedrooms">2 Bedrooms</option>
                  <option value="3 Bedrooms">3 Bedrooms</option>
                  <option value="Penthouse">Penthouse</option>
                </Form.Select>
                {errors.moving_from_apartment_type && (
                  <Form.Text className="text-danger">
                    {errors.moving_from_apartment_type}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Number of room(s) moving from <span></span>
                </Form.Label>
                <Form.Select
                  name="number_of_room_moving_from"
                  value={formData.number_of_room_moving_from || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5+">5+</option>
                </Form.Select>
                {errors.number_of_room_moving_from && (
                  <Form.Text className="text-danger">
                    {errors.number_of_room_moving_from}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Moving from Floor <span></span>
                </Form.Label>
                <Form.Select
                  name="aptFloor"
                  value={formData.aptFloor || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="Ground">Ground</option>
                  <option value="1st">1st</option>
                  <option value="2nd">2nd</option>
                  <option value="3rd">3rd</option>
                  <option value="4th+">4th+</option>
                </Form.Select>
                {errors.aptFloor && (
                  <Form.Text className="text-danger">
                    {errors.aptFloor}
                  </Form.Text>
                )}
              </Form.Group>
              <h6>
                Fields with <span></span> are required
              </h6>
            </div>
            <div className="col formColl">
              <Form.Group className="form-group">
                <Form.Label>
                  Moving to address <span></span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="moving_to_address"
                  value={formData.moving_to_address || ""}
                  onChange={handleInputChange}
                />
                {errors.moving_to_address && (
                  <Form.Text className="text-danger">
                    {errors.moving_to_address}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Moving to apartment type <span></span>
                </Form.Label>
                <Form.Select
                  name="moving_to_apartment_type"
                  value={formData.moving_to_apartment_type || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="Studio">Studio</option>
                  <option value="1 Bedroom">1 Bedroom</option>
                  <option value="2 Bedrooms">2 Bedrooms</option>
                  <option value="3 Bedrooms">3 Bedrooms</option>
                  <option value="Penthouse">Penthouse</option>
                </Form.Select>
                {errors.moving_to_apartment_type && (
                  <Form.Text className="text-danger">
                    {errors.moving_to_apartment_type}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Number of room(s) moving to <span></span>
                </Form.Label>
                <Form.Select
                  name="number_of_room_moving_to"
                  value={formData.number_of_room_moving_to || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5+">5+</option>
                </Form.Select>
                {errors.number_of_room_moving_to && (
                  <Form.Text className="text-danger">
                    {errors.number_of_room_moving_to}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Moving to Floor <span></span>
                </Form.Label>
                <Form.Select
                  name="toAptFloor"
                  value={formData.toAptFloor || ""}
                  onChange={handleInputChange}
                >
                  <option>Select</option>
                  <option value="Ground">Ground</option>
                  <option value="1st">1st</option>
                  <option value="2nd">2nd</option>
                  <option value="3rd">3rd</option>
                  <option value="4th+">4th+</option>
                </Form.Select>
                {errors.toAptFloor && (
                  <Form.Text className="text-danger">
                    {errors.toAptFloor}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="row">
            <div className="col-md-6 formColl">
              <h6>
                Fields with <span></span> are required
              </h6>
              <Form.Group className="form-group">
                <Form.Label>
                  Full name <span></span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="full_name"
                  value={formData.full_name || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Email <span></span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>
                  Mobile number <span></span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="mobile_phone"
                  value={formData.mobile_phone || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="row">
            <div className="col-md-6 formColl">
              <h6>
                Fields with <span></span> are required
              </h6>
              <Form.Group className="form-group">
                <Form.Label>
                  Select services <span>*</span>
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  id="packing_and_loading"
                  label="Packing and Loading"
                  onChange={handleServiceChange}
                />
                <Form.Check
                  type="checkbox"
                  id="unpacking_and_arranging"
                  label="Unpacking and Arranging"
                  onChange={handleServiceChange}
                />
                <Form.Check
                  type="checkbox"
                  id="just_moving"
                  label="Just Moving"
                  onChange={handleServiceChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Choose moving date</Form.Label>
                <Form.Control
                  type="date"
                  name="moving_date"
                  value={formData.moving_date || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Choose inspection date</Form.Label>
                <Form.Control
                  type="date"
                  name="date_of_inspection"
                  value={formData.date_of_inspection || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
            <div className="col-md-6 formColl">
              <Form.Group className="form-group">
                <Form.Label>
                  Upload image(s) / video of your apartment
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={(e) =>
                    setFormData({ ...formData, files: e.target.files })
                  }
                />
              </Form.Group>
            </div>
            <div className="col-12 formColl">
              <Form.Group className="form-group">
                <Form.Label>Additional information</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="termsAndConditions"
                  label="I agree to the Terms & Conditions of JVT Logistics"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      termsAccepted: e.target.checked,
                    })
                  }
                />
              </Form.Group>
            </div>
          </div>
        )}
        <div className="buttons">
          {step > 1 && (
            <Button className="btn-back" onClick={handlePrevious}>
              Back
            </Button>
          )}
          {step < 3 ? (
            <Button className="btn-next" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button className="btn-next" type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          )}
        </div>
      </Form>
    </section>
  );
};
