import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export const Intro = () => {
  const headingRef = useRef(null);
  const paragraphRef = useRef(null);

  useEffect(() => {
    typewriterEffect(
      paragraphRef.current,
      "We don't just move boxes, we move memories, lives and dreams.",
      0
    );
  }, []);

  const typewriterEffect = (element, text, i, callback) => {
    if (i < text.length) {
      element.innerHTML =
        text.substring(0, i + 1) + '<span class="caret">|</span>';
      setTimeout(() => typewriterEffect(element, text, i + 1, callback), 100);
    } else {
      element.innerHTML = text;
      if (callback) callback();
    }
  };

  const typewriter = {
    fontFamily: "'Courier New', Courier, monospace, Gilroy",
  };

  const caretStyle = `
    .caret {
      border-right: .1em solid black;
      animation: blink-caret .75s step-end infinite;
    }

    @keyframes blink-caret {
      from, to { border-color: transparent; }
      50% { border-color: black; }
    }
  `;

  return (
    <section className="intro lg:h-[660px] mt-2">
      <style>{caretStyle}</style>
      <div className="introText">
        <h2 className="my-3" ref={headingRef} style={typewriter}></h2>
        <h2 className="my-3 text-[#F3B734]">
          We ensure a smooth moving experience.
        </h2>
        <div className="text-[#F5F5F5]">
          <p ref={paragraphRef} style={typewriter}></p>
        </div>
        <div className="buttons pt-5">
          <a
            href="/delivery"
            className="btn getQuote sm:text-xs font-bold lg:text-base py-3 hover:bg-transparent hover:border hover:border-white hover:text-white"
          >
            Book a Delivery
          </a>
          <a
            href="/relocation"
            className="btn trackPackage sm:text-xs font-bold lg:text-base lg:py-3  "
          >
            Get a Relocation Quote
          </a>
        </div>
      </div>
    </section>
  );
};
