import React, { useEffect, useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === password_confirmation) {
      setIsLoading(true);

      apiClient
        .post("/register", {
          fullname,
          email,
          password,
          password_confirmation,
          phone,
        })
        .then((response) => {
          if (response.data.message === "User created successfully") {
            toast.success("Registration Successful");
            setTimeout(() => {
              navigate("/email-verification");
            }, 3000);
          } else {
            toast.error("Registration failed. Please try again.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.data) {
            const errorData = error.response.data;
            Object.keys(errorData).forEach((key) => {
              errorData[key].forEach((message) => {
                toast.error(message);
              });
            });
          } else {
            setIsLoading(false);
            console.error(error);
            toast.error("An error occurred. Please try again.");
          }
        });
    } else {
      toast.error("Passwords do not match");
    }
  };

  return (
    <div className="app">
      {/* <Header /> */}
      <ToastContainer />
      <div className="mt-2 mb-10">
        <Link to={"/"}>
          {" "}
          <div className="sm:pl-10 sm:py-3 lg:pl-32">
            {" "}
            <img src="/new-logo.png" alt="logo" />
          </div>
        </Link>

        <div className="signup">
          <h1 className="pb-4">
            <span className="decoration-[#F3B734] underline-offset-[16px] underline">
              Sign{" "}
            </span>
            Up
          </h1>

          {isMobile ? (
            <div
              style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
            >
              <p style={{ marginTop: "1rem" }}>
                Already have an account? Please{" "}
                <div style={{ marginTop: ".5rem" }}>
                  <Link to="/login" style={{}}>
                    <p className="hover:text-[#a27a23]">Log In | </p>
                  </Link>
                  <Link
                    to="/email-verification"
                    className="!hover:text-[#a27a23]"
                  >
                    <p className="!hover:text-red-300">Verify email</p>
                  </Link>
                </div>
              </p>
            </div>
          ) : (
            <p style={{ marginTop: "1rem", display: "flex", gap: ".5rem" }}>
              Already have an account? Please{" "}
              <div>
                <Link to="/login">Log In | </Link>
                <Link to="/email-verification">Verify email</Link>
              </div>
            </p>
          )}

          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  placeholder="John Doe"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="johndoe@gmail.com"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="tel" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="08116727424"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="pwd" className="form-label">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                    className="form-control"
                    required
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center ps-3.5 px-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="c-pwd" className="form-label">
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password_confirmation}
                    onChange={(e) => setPassword_confirmation(e.target.value)}
                    placeholder="********"
                    className="form-control"
                    required
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center ps-3.5 px-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-3"
                >
                  {isLoading ? "Signing Up..." : "Sign Up"}
                </button>
              </div>
              {/* <div className="form-link">
                <p>Or Sign Up with</p>
                <a href="">
                  <i className="bi bi-google"></i>
                </a>
                <a href="">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i className="bi bi-apple"></i>
                </a>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
