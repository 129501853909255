import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Components from "../../../Components";
import BookAnAppointment from "../../appointment/bookAnAppointment";

export const Local = () => {
  return (
    <section className="">
      <section
        className={`h-[400px] flex flex-col lg:flex-row bg-no-repeat bg-cover lg:h-[594px]  bg-[linear-gradient(to_right,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('/public/assets/img/local.png')] w-screen overflow-x-hidden page-enter page-enter-active 
          `}
      >
        <div className="flex flex-col items-center justify-center mx-auto py-4">
          <h2 className="my-3 text-[#F3B734] font-semibold text-4xl">
            Local Relocation
          </h2>
          <div className="text-[#F5F5F5] max-w-[592px] font-semibold text-sm">
            <p>
              Your hassle-free solution for moving within the state! Let's make
              your relocation journey smooth and stress-free.
            </p>
          </div>
          <div className="pt-10 flex sm:flex-col lg:flex-row gap-4">
            <a
              href="/local-quote"
              className=" py-3 w-[200px] bg-[#F5F5F5] hover:bg-transparent hover:border hover:border-white hover:text-white rounded-[4px] "
            >
              Get a Quote
            </a>
            <a
              href="/track"
              className="py-3 hover:bg-[#F5F5F5] border border-white text-[#f5f5f5] rounded-[4px] w-[200px] hover:text-black"
            >
              Track
            </a>
          </div>
        </div>
      </section>

      <div className="bg-white lg:mx-20 mt-5 py-5 shadow-lg rounded-2xl">
        <h2 className="text-center font-medium text-4xl text-[#212120] pb-3">
          WE PROVIDE ADDED VALUE
        </h2>
        <p className="text-[#212120] font-semibold text-sm text-center max-w-[790px] mx-auto">
          When it comes to transporting your valuable furniture, trust only
          experienced professionals. Our team is not only fit and friendly but
          also specially trained to handle delicate and expensive items. We
          offer comprehensive packing services, utilize special equipment and
          materials for furniture handling, and ensure sensible pricing.
        </p>
      </div>
      <Components.WhyUs />
      <div className="pb-20">
        {" "}
        <BookAnAppointment />
      </div>
    </section>
    // <section style={containerStyle} className="Local container">
    //   <div style={introStyle} className="Localintro">
    //     <div style={textStyle} className="text">
    //       <h1 style={titleStyle}>Welcome To JVT Logistics.</h1>
    //       <div>
    //         <p style={paragraphStyle}>
    //           Your hassle-free solution for moving within the state! Let's make
    //           your relocation journey smooth and stress-free.
    //         </p>
    //       </div>
    //       <div className="button">
    //         <button style={buttonStyle}>
    //           <Link to={"/local-quote"} style={{ color: "#212120" }}>
    //             Quote
    //           </Link>
    //         </button>
    //       </div>
    //     </div>
    //     <div style={imageStyle} className="image">
    //       <img
    //         src="./assets/img/service/LocalIntro.png"
    //         alt=""
    //         style={{ width: "100%" }}
    //       />
    //     </div>
    //   </div>
    //   <div style={valueStyle} className="value">
    //     <h2 style={valueTitleStyle}>Why Choose Us</h2>
    //     <p style={paragraphStyle}>
    //       Your furniture deserves the utmost care during transportation, and
    //       that's exactly what our expert team provides. And with our transparent
    //       pricing structure, you can trust that you're getting exceptional
    //       service without breaking the bank.
    //     </p>
    //   </div>
    // </section>
  );
};
