/** @format */

import React, { useRef } from "react";

const WhatOurCustomersSay = () => {
  const scrollContainerRef = useRef(null);

  // Function to scroll left
  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  // Function to scroll right
  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };
  return (
    <section
      className="
   relative bg-white sm:px-5 lg:px-[160px] py-4 pt-9 pb-20 2xl:px-[300px]"
    >
      <h2 className="text-center w-[200px] text-black font-medium text-2xl mx-auto pb-7">
        What our <span className="font-bold text-[#A27A23]">customers say</span>
      </h2>
      {/* Left Arrow */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-[60%] transform -translate-y-[50%] bg-[#A27A23] text-white px-3 lg:mx-[100px] py-2 rounded-full shadow-md"
      >
        &#8249;
      </button>
      <div
        ref={scrollContainerRef}
        className="flex gap-10 overflow-x-auto scroll-smooth"
      >
        <div className="text-justify w-[330px] tracking-tight shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT Logistics made my move seamless with their professional and
            punctual team. They handled everything with care from start to
            finish. Highly recommend!
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">Ritesh K.</p>
        </div>
        <div className="text-justify w-[330px] tracking-tight  shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT exceeded my expectations! They packed and transported my items
            securely and efficiently, making the move stress-free. Fantastic
            experience!
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">Taiwo A.</p>
        </div>
        <div className="text-justify w-[330px] tracking-tight  shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT was professional and careful with my belongings. Though they
            arrived late, the overall experience was great, and I'd use them
            again!
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">Charles O.</p>
        </div>
        <div className="text-justify w-[330px] tracking-tight  shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT Logistics made my move stress-free and efficient. The courteous
            team packed everything with care, and nothing was damaged. Highly
            recommend!
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">Ijeoma N.</p>
        </div>
      </div>
      {/* Right Arrow */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-[60%] transform -translate-y-[50%] bg-[#A27A23] text-white px-3 py-2 lg:mx-[100px] rounded-full shadow-md"
      >
        &#8250;
      </button>
    </section>
  );
};

export default WhatOurCustomersSay;
