import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { useAuth } from "../../Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResendOtp() {
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .post("/resendOtp", { email })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "OTP resent successfully") {
          toast.success("OTP resent successfully");

          setTimeout(() => {
            navigate("/email-verification");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="app">
      <ToastContainer />
      <div className="mt-2 mb-10">
        <Link to={"/"}>
          {" "}
          <div className="lg:pl-32">
            {" "}
            <img src="/new-logo.png" alt="logo" />
          </div>
        </Link>

        <div className="login">
          <h1 className="pb-4">
            {" "}
            <span className="decoration-[#F3B734] underline-offset-[16px] underline">
              Resend
            </span>{" "}
            Otp{" "}
          </h1>

          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Johndoe@gmail.com"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-3"
                >
                  {isLoading ? "Loading..." : "Resend"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
