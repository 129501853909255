import React, { useState, useEffect } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { useAuth } from "../../Context";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxAvatar } from "react-icons/rx";
import { FiCamera } from "react-icons/fi";

const ProfileUpdate = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(
    localStorage.getItem("profile_image") || ""
  );

  const email = sessionStorage.getItem("email");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("phone", phone);
    if (profileImage) {
      formData.append("profile_image", profileImage);
    }

    apiClient
      .post(`/users/edit/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "User Profile updated successfully.") {
          toast.success("User Profile updated successfully");
          localStorage.setItem(
            "profile_image",
            response.data?.data?.profile_image
          );
          setTimeout(() => {
            navigate("/profile");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  const handleLogout = () => {
    logout();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileImageUrl(imageUrl);
    }
  };

  const buttonStyle = {
    backgroundColor: "#F3B734",
    color: "#212120",
    border: "none",
    padding: "6px 12px",
    borderRadius: "10px",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "none",
    display: "inline-block",
    transition: "background-color 0.3s ease",
    textAlign: "center",
  };

  const buttonHoverStyle = {
    backgroundColor: "#d29f2a",
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="flex mt-36 lg:mx-24 sm:mx-2 sm:gap-2 lg:gap-5 2xl:px-[200px]">
        <div className="bg-[#FFEABB] h-[85vh] w-[250px] flex flex-col justify-between items-center pt-2">
          <div>
            {" "}
            <h3 className=" font-semibold lg:text-2xl text-[#212120] pb-3">
              <Link to={"/profile-update"}>Profile</Link>
            </h3>
            <p className="text-[#F3B734] font-semibold lg:text-2xl">
              <Link to={"/profile"}>Account</Link>
            </p>
          </div>

          <div className="pb-3">
            <p className="font-semibold lg:text-2xl text-[#212120] bg-[#F3B734] py-2 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a] ">
              <Link className="getstarted" onClick={handleLogout}>
                Log Out
              </Link>
            </p>
          </div>
        </div>
        <div className="bg-[#F5F5F5] sm:w-full lg:h-[85vh] lg:w-[70vw] p-4 lg:p-6">
          <div className="form">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col lg:w-[60%] space-y-4"
            >
              <div className="form-group">
                <label htmlFor="profile_image" className="form-label">
                  Profile Image
                </label>
                <div className="flex items-center space-x-4 relative">
                  <div
                    className="relative w-[70px] h-[70px] rounded-full cursor-pointer overflow-hidden"
                    onClick={() =>
                      document.getElementById("profileImageInput").click()
                    }
                  >
                    {profileImageUrl.length > 4 ? (
                      <img
                        src={profileImageUrl}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="bg-gray-300 w-full h-full flex items-center justify-center text-gray-600">
                        <RxAvatar className="w-[45px] h-[45px]" />
                      </div>
                    )}
                    <div className="absolute bottom-0 right-0 bg-gray-800 text-white rounded-full p-2">
                      <FiCamera className="w-[13px] h-[13px]" />
                    </div>
                  </div>
                  <input
                    type="file"
                    id="profileImageInput"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="fullname" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  placeholder="Input Name"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  value={email}
                  placeholder={email}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Input Phone"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex justify-content-end">
                <button
                  style={buttonStyle}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      buttonHoverStyle.backgroundColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor =
                      buttonStyle.backgroundColor)
                  }
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#F3B734] px-4 py-2.5 font-semibold"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileUpdate;
