import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { useAuth } from "../../Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookAnAppointment from "../../Components/appointment/bookAnAppointment";

export default function Track() {
  const [reference_id, setReference_id] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");
    apiClient
      .post(
        "/users/tracking/status",
        { reference_id },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Package found") {
          toast.success("Package found");
          const serviceTypeFullString = getServiceTypeFullString(
            response.data.data.service_type
          );
          navigate("/package-details", {
            state: {
              referenceNumber: response.data.data.reference_number,
              serviceType: serviceTypeFullString,
              status: response.data.data.status,
              progressBar: response?.data?.data?.ProgressBar,
              dateCreated: response?.data?.data["date created"],
              dateUpdated: response?.data?.data["date updated"],
            },
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  const getServiceTypeFullString = (serviceType) => {
    switch (serviceType) {
      case "local_service":
        return "Local Service";
      case "office_service":
        return "Office Service";
      case "state_service":
        return "State Service";
      default:
        return serviceType;
    }
  };
  return (
    <div className="">
      <Header />
      <ToastContainer />
      <div className="mt-36 mb-20 bg-[#DCDBDA] 2xl:flex 2xl:justify-center">
        <div className=" pt-8 sm:px-3 md:px-10 pb-10 lg:pb-36 lg:px-72">
          <h1 className=" font-normal text-2xl pb-4 lg:text-5xl text-[#A27003] lg:pb-16">
            Track Your Relocation Status{" "}
          </h1>

          <div className="">
            <form onSubmit={handleSubmit} className="flex">
              <div className="">
                <label htmlFor="reference_id" className="hidden">
                  Reference Id
                </label>
                <input
                  type="text"
                  value={reference_id}
                  onChange={(e) => setReference_id(e.target.value)}
                  placeholder="Tracking Code"
                  className="h-[66px] md:w-[300px] lg:w-[409px] px-4 rounded-tl-[4px] rounded-bl-[4px]"
                  required
                />
              </div>
              <div>
                {" "}
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#F3B734] h-[66px] sm:px-8 md:px-14 lg:px-0  lg:w-[268px] text-[#212120] text-sm rounded-tr-[4px] rounded-br-[4px] "
                >
                  {isLoading ? "Tracking..." : "Track"}
                </button>
              </div>
            </form>
          </div>
          <p className="pt-4 text-[#343432] font-normal text-sm">
            Need help changing your move date?{" "}
            <span className="text-[#F3B734]">
              <Link to={"/"}>Support</Link>
            </span>
          </p>
        </div>
      </div>
      <BookAnAppointment />
      <Footer />
    </div>
  );
}
