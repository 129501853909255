import React from "react";

const WhyMoveWithUs = () => {
  return (
    <section className="bg-[#F7DEA8] px-5 lg:px-[160px] pt-9 pb-20">
      <h2 className="text-center text-[#A27A23] font-semibold text-2xl pb-2 ">
        Why Move with Us?
      </h2>
      <div className="pb-8">
        <h3 className="text-center text-[#212120] font-semibold lg:text-[40px] leading-[24px] lg:leading-[48.86px] max-w-[510px] mx-auto">
          We don't Just Move Boxes,
        </h3>
        <h3 className="text-center text-[#212120] font-semibold lg:text-[40px] leading-[24px] lg:leading-[48.86px] max-w-[700px] mx-auto">
          We Move Memories and Dreams
        </h3>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-center gap-6">
        <div className="w-[278px] h-[232px] bg-[#F3B734] rounded-[16px] px-8 py-3">
          <div className="flex items-center justify-center">
            <img src="./assets/img/rush.png" alt="speed you can trust" />
          </div>
          <p className=" pb-2 text-center text-xl font-medium text-[#212120] ">
            Speed You Can Trust
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            Fast, on-demand deliveries that get your packages where they need to
            be—right when you need them.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-[#F3B734] rounded-[16px] px-8 py-3">
          <div className="flex items-center justify-center">
            <img src="./assets/img/truck.png" alt="seamless scheduling" />
          </div>
          <p className=" pb-2 text-center text-xl font-medium text-[#212120] ">
            Seamless Scheduling
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            Easily plan deliveries in advance and count on us to deliver at the
            perfect time.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-[#F3B734] rounded-[16px] px-8 py-3">
          <div className="flex items-center justify-center">
            <img src="./assets/img/multi.png" alt="multi stops, one booking" />
          </div>
          <p className=" pb-2 text-center text-xl font-medium text-[#212120] ">
            Multiple Stops, One Booking
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            Need to send items to different locations? Book multi-stop
            deliveries with ease and efficiency.
          </p>
        </div>
        <div className="w-[278px] h-[232px] bg-[#F3B734] rounded-[16px] px-8 py-3">
          <div className="flex items-center justify-center">
            <img src="./assets/img/secure.png" alt="secure and reliable" />
          </div>
          <p className=" pb-2 text-center text-xl font-medium text-[#212120] ">
            Secure & Reliable
          </p>
          <p className="text-center text-[#343432] font-normal text-base">
            Your packages are handled with care and delivered safely, ensuring
            peace of mind every time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyMoveWithUs;
