import React from "react";

const OurDeliveryServices = () => {
  return (
    <section id="delivery-services" className="my-14">
      <h3 className=" text-[#212120] text-[40px] leading-[49px] font-semibold text-center pb-6">
        Our Delivery Services
      </h3>
      <div className="mx-auto flex flex-col items-center lg:flex-row gap-8 lg:justify-center lg:px-24">
        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-3 px-7">
          <div>
            <img src="./assets/img/express.png" alt="office-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-2">
            Express Delivery
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-[20px] text-center">
            Need it there fast? Choose Express Delivery for immediate delivery.
            We’ll get your items where they need to be, right away.
          </p>
          <a
            href="/express-delivery"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-2.5 rounded-md"
          >
            Book Now
          </a>
        </div>

        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-3 px-7">
          <div>
            <img src="./assets/img/schedule.png" alt="local-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-2">
            Schedule Delivery
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-[37px] text-center">
            Want to plan ahead? Use Schedule Delivery to book a delivery for a
            future date that fits your schedule.
          </p>
          <a
            href="/schedule-delivery"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-2.5 rounded-md"
          >
            Book Now
          </a>
        </div>

        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-3 px-7">
          <div>
            <img src="./assets/img/bulk.png" alt="interstate-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-2">
            Bulk Delivery
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-[37px] text-center">
            Have multiple stops? Bulk Delivery lets you send items to several
            locations in one smooth trip.
          </p>
          <a
            href="/bulk-delivery"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-2.5 rounded-md"
          >
            Book Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default OurDeliveryServices;
