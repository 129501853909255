import React, { createContext, useState, useEffect } from "react";
import apiClient from "./api/api";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isStickyOn, setIsStickyOn] = useState(true);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = async () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      try {
        await apiClient.get("/users/logout", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        localStorage.removeItem("authToken");
        localStorage.removeItem("id");
        localStorage.removeItem("profile_image");

        setIsAuthenticated(false);
        window.location.href = "/";
      } catch (error) {
        console.error("Error logging out:", error);
      }
    } else {
      console.error("No auth token found");
      window.location.href = "/";
    }
  };

  const offSticky = () => {
    setIsStickyOn(false);

    setTimeout(() => {
      setIsStickyOn(true);
    }, 30000);
  };

  const checkAuthStatus = () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isStickyOn,
        login,
        logout,
        offSticky,
        checkAuthStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
