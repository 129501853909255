import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Context";
import "./App.css";
import Home from "./Pages/homepage/Home";
import Local from "./Pages/services/Local";
import Login from "./Pages/login/Login";
import SignUp from "./Pages/signup/SignUp";
import Interstate from "./Pages/services/Interstate";
import Office from "./Pages/services/Office";
import Store from "./Pages/services/Store";
import Partner from "./Pages/partner/Partner";
import Move from "./Pages/partner/Move";
import Pack from "./Pages/partner/Pack";
import Services from "./Pages/services/Services";
import About from "./Pages/about/About";
import Contact from "./Pages/contact/Contact";
import GetQuote from "./Pages/quote/Quote";
import FAQ from "./Pages/Faq";
import Terms from "./Pages/Terms";
import ProtectedRoute from "./Components/ProtectedRoute";
import ForgotPassword from "./Pages/forgot-password/ForgotPassword";
import OtpVerification from "./Pages/otp-verification/OtpVerification";
import { InterstateQuote } from "./Pages/interstate-quote/InterstateQuote";
import { LocalQuote } from "./Pages/local/local";
import { OfficeQuote } from "./Pages/office/office";
import ResetPassword from "./Pages/reset-password/ResetPassword";
import EmailVerification from "./Pages/email-verification/EmailVerification";
import Track from "./Pages/track/Track";
import PackageDetails from "./Pages/package-details/PackageDetails";
import Profile from "./Pages/profile/Profile";
import ChangePassword from "./Pages/profile/ChangePassword";
import DeactivateAccount from "./Pages/profile/DeactivateAccount";
import Thankyou from "./Pages/services/Thankyou";
import ProfileUpdate from "./Pages/profile/ProfileUpdate";
import Relocation from "./Pages/services/Relocation";
import Delivery from "./Pages/services/Delivery";
import { ExpressDelivery } from "./Pages/Delivery/ExpressDelivery";
import { ScheduleDelivery } from "./Pages/Delivery/ScheduleDelivery";
import { BulkDelivery } from "./Pages/Delivery/BulkDelivery";
import ResendOtp from "./Pages/resend-verification-otp/ResendOtp";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/resend-otp" element={<ResendOtp />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/local" element={<Local />} />
          <Route path="/office" element={<Office />} />
          <Route path="/interstate" element={<Interstate />} />
          <Route path="/store" element={<Store />} />
          <Route path="/services" element={<Services />} />
          <Route path="/relocation" element={<Relocation />} />
          <Route path="delivery" element={<Delivery />} />
          <Route path="/partner" element={<Partner />} />

          <Route path="/move" element={<Move />} />
          <Route path="/pack" element={<Pack />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms_and_conditions" element={<Terms />} />
          <Route
            path="/get-quote"
            element={<ProtectedRoute element={<GetQuote />} />}
          />
          <Route
            path="/interstate-quote"
            element={<ProtectedRoute element={<InterstateQuote />} />}
          />
          <Route
            path="/local-quote"
            element={<ProtectedRoute element={<LocalQuote />} />}
          />
          <Route
            path="/office-quote"
            element={<ProtectedRoute element={<OfficeQuote />} />}
          />
          <Route
            path="/express-delivery"
            element={<ProtectedRoute element={<ExpressDelivery />} />}
          />
          <Route
            path="/schedule-delivery"
            element={<ProtectedRoute element={<ScheduleDelivery />} />}
          />
          <Route
            path="/bulk-delivery"
            element={<ProtectedRoute element={<BulkDelivery />} />}
          />
          <Route
            path="/track"
            element={<ProtectedRoute element={<Track />} />}
          />
          <Route
            path="/package-details"
            element={<ProtectedRoute element={<PackageDetails />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/profile-update"
            element={<ProtectedRoute element={<ProfileUpdate />} />}
          />
          <Route
            path="/change-password"
            element={<ProtectedRoute element={<ChangePassword />} />}
          />
          <Route
            path="/deactivate-account"
            element={<ProtectedRoute element={<DeactivateAccount />} />}
          />
          <Route
            path="/thank-you"
            element={<ProtectedRoute element={<Thankyou />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
