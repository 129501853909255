import React from "react";
import * as Components from "../../Components";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";

export default function Partner() {
  return (
    <div className="app">
      <Header />
      <div className="section">
        <Components.Partner />

        <Components.StickyBar />
      </div>
      <Footer />
    </div>
  );
}
