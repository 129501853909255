import React from "react";
import { Link } from "react-router-dom";

export const Services = () => {
  return (
    <section className="container Services">
      <h1>Our Services</h1>
      <p>
        Explore our range of services to discover the one that best fits your
        needs.
      </p>
      <div className="serList">
        <div className="row">
          <div className="col">
            <Link to="/office" className="imgCont">
              <h3>Office Move</h3>
              <img src="./assets/img/home/officeRelo.png" alt="" />
            </Link>
          </div>
          <div className="col">
            <Link to="/store" className="imgCont">
              <h3>Deliver a Package</h3>
              <img src="./assets/img/home/storeDel.png" alt="" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Link to="/local" className="imgCont">
              <h3>Local Move</h3>
              <img src="./assets/img/home/localMove.png" alt="" />
            </Link>
          </div>
          <div className="col">
            <Link to="/interstate" className="imgCont">
              <h3>Inter-state Move</h3>
              <img src="./assets/img/home/interMove.png" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
