import React from "react";

const DeliveryHeroSection = () => {
  return (
    <section className="sm:px-5 lg:px-[160px] pt-24  lg:pt-12 pb-12 flex flex-col lg:flex-row gap-10 lg:gap-[117px] w-full lg:justify-center">
      <div className="lg:w-[30%] flex flex-col sm:text-center md:text-justify lg:justify-center">
        <h2 className="text-[#A27A23] font-semibold text-4xl pb-3">Delivery</h2>
        <p className="text-[#343432] font-semibold text-sm pb-8">
          Need something delivered right away, scheduled for later, or sent to
          multiple locations? Our delivery services have you covered. Choose
          <span className="font-bold"> 'Deliver Now' </span>for urgent
          deliveries,<span className="font-bold"> 'Scheduled Delivery'</span> to
          plan ahead, or <span className="font-bold"> 'Bulk Delivery'</span> to
          reach several destinations in one go. We're here to make your
          deliveries simple and stress-free, every time.
        </p>
        <div className="sm:mt-5 sm:flex sm:justify-center lg:justify-normal">
          {" "}
          <a
            href="#delivery-services"
            className="bg-[#F3B734] hover:bg-[#a27a23] rounded-md py-3 w-[240px] text-[#212120] font-semibold text-xl text-center "
          >
            Let’s Help You Deliver
          </a>
        </div>
      </div>
      <div className="">
        <img src="./assets/img/delivery.png" alt="delivery hero section" />
      </div>
    </section>
  );
};

export default DeliveryHeroSection;
