import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { useAuth } from "../../Context";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeactivateAccount = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [password, setPassword] = useState("");
  const [Reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");
    apiClient
      .post(
        "/users/DeleteUser",
        {
          password,
          Reason,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.succes === "User data deleted successfully.") {
          toast.success("User data deleted successfully.");

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="flex mt-36 lg:mx-24 sm:mx-2 sm:gap-2 lg:gap-5">
        <div className="bg-[#FFEABB] h-[70vh] w-[250px] flex flex-col justify-between items-center pt-2">
          <div>
            {" "}
            <h3 className=" font-semibold lg:text-2xl text-[#212120] pb-3">
              <Link to={"/profile-update"}>Profile</Link>
            </h3>
            <p className="text-[#F3B734] font-semibold lg:text-2xl">
              <Link to={"/profile"}>Account</Link>
            </p>
          </div>

          <div className="pb-3">
            <p className="font-semibold lg:text-2xl text-[#212120] bg-[#F3B734] py-2 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a] ">
              <Link className="getstarted" onClick={handleLogout}>
                Log Out
              </Link>
            </p>
          </div>
        </div>
        <div className="bg-[#F5F5F5] sm:w-full lg:h-[70vh] lg:w-[70vw]  p-6">
          <div className="form">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col lg:w-[70%] space-y-4"
            >
              <div className="form-group">
                <label htmlFor="reason" className="form-label">
                  Please provide a reasons for deleting your account
                </label>
                <textarea
                  name="reason"
                  value={Reason}
                  rows={6}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder=""
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder=""
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#F3B734] px-4 py-2.5 font-semibold rounded-md cursor-pointer hover:bg-[#d29f2a]"
                >
                  {isLoading ? "Deleting..." : "Delete account"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeactivateAccount;
