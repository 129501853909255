import React, { useContext } from "react";
import * as Components from "../../Components";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import BookAnAppointment from "../../Components/appointment/bookAnAppointment";

export default function Home() {
  return (
    <div className="app">
      <Header />
      <div className="section">
        <Components.Intro />
        <div className="flex flex-col lg:flex-row w-full sm:p-5 md:justify-center lg:my-20 gap-10 lg:gap-16   text-left lg:justify-center">
          <div className="  lg:w-[500px] 2xl:w-[700px]">
            <h2 className=" text-[#F3B734] font-bold text-base uppercase mb-2 sm:text-center lg:text-left">
              About{" "}
            </h2>
            <h3 className="text-[#212120] font-medium text-4xl mb-3 sm:text-center lg:text-left">
              JVT Logistics
            </h3>
            <p className=" text-[#343432] font-semibold text-sm sm:text-center lg:text-justify">
              JVT Logistics is your go-to logistics partner for hassle-free
              moves within and outside the state. We specialize in making
              relocation easy, whether it's for your home or office. Our
              experienced team handles everything from packing to transportation
              with care and precision. Count on us for a smooth, stress-free
              moving experience. Let's make your move effortless together.
            </p>
            <div className="sm:mt-5 sm:flex sm:justify-center lg:justify-normal lg:mt-10">
              <a
                href="/about"
                className="py-3 px-10 bg-[#F3B734] hover:bg-[#a27a23] text-[#212120] font-semibold rounded"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="md:mx-auto lg:mx-0">
            <img src="./image 29.png" alt="about-us" />
          </div>
        </div>
        <Components.Service />
        <Components.WhyUs />
        <div className="pb-20">
          {" "}
          <BookAnAppointment />
        </div>

        <Components.StickyBar />
      </div>
      <Footer />
    </div>
  );
}
