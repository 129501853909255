import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookAnAppointment from "../../appointment/bookAnAppointment";

export const Pack = () => {
  const [email, setEmail] = useState("");
  const [full_name, setFull_name] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [age, setAge] = useState("");
  const [location, setLocation] = useState("");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    apiClient
      .post("/Packer-Registration", {
        full_name,
        email,
        age,
        mobile_number,
        location,
      })
      .then((response) => {
        console.log(response?.data?.message);
        if (
          response?.data?.message ===
          "Your request to become a Packer for JVT Logistics has been created successfully"
        ) {
          toast.success("Application Successful");
          setIsLoading(false);
          setTimeout(() => {
            navigate("/partner");
          }, 3000);
        } else {
          setIsLoading(false);
          toast.error("Application failed. Please try again.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          Object.keys(errorData).forEach((key) => {
            errorData[key].forEach((message) => {
              toast.error(message);
            });
          });
        } else {
          setIsLoading(false);
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="w-full pt-20">
      {" "}
      <section className=" sm:px-5 lg:px-40">
        <div className="flex sm:flex-col lg:flex-row sm:gap-10 lg:gap-48 mb-5 lg:justify-center">
          <div className="flex flex-col justify-center text-left max-w-[323px]">
            <h1 className=" text-[#A27003] font-semibold text-4xl pb-2">
              Become a Packer
            </h1>
            <p className="text-[#212120] font-semibold text-sm text-justify">
              Access work remotely via our platform, allowing you to set your
              own schedule and embark on your entrepreneurial journey.
            </p>
          </div>
          <div className="">
            <div className="">
              <img src="./assets/img/home/packer.png" alt="" />
            </div>
          </div>
        </div>
        <div className="w-[300px] text-left lg:w-[600px] 2xl:pl-[130px] 2xl:w-[700px]">
          <div className="">
            <form className="" onSubmit={handleSubmit}>
              <h5 className="text-[#212120] font-semibold text-2xl pb-2">
                Fields with <span className="text-red-600">*</span> are required
              </h5>
              <div className="pb-2 my-2">
                <label className=" text-[#212120] pb-2 font-normal text-base">
                  Full name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  value={full_name}
                  onChange={(e) => setFull_name(e.target.value)}
                  className="form-control my-1 py-3"
                  placeholder="Input name"
                  required
                />
              </div>
              <div className="pb-2 my-2">
                <label className="text-[#212120] font-normal pb-2 text-base">
                  Age <span className="text-red-600">*</span>
                </label>
                <input
                  type="number"
                  className="form-control my-1 py-3"
                  placeholder="Input age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                />
              </div>
              <div className="pb-2 my-2">
                <label className="text-[#212120] font-normal pb-2 text-base">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control my-1 py-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Input email"
                />
              </div>
              <div className="pb-2 my-2">
                <label className="text-[#212120] font-normal pb-2 text-base">
                  Mobile number <span className="text-red-600">*</span>
                </label>
                <input
                  type="tel"
                  className="form-control my-1 py-3"
                  placeholder="Input number"
                  value={mobile_number}
                  onChange={(e) => setMobile_number(e.target.value)}
                  required
                />
              </div>
              <div className="pb-2 my-2">
                <label className="text-[#212120] font-normal pb-2 text-base">
                  Location <span className="text-red-600">*</span>
                </label>
                <input
                  type="address"
                  className="form-control my-1 py-3"
                  placeholder="Input location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                />
              </div>
              <div className="form-group my-3 text-right">
                <button
                  type="submit"
                  className=" px-7 rounded-md py-2 bg-[#F3B734] text-xl font-semibold text-[#212120]"
                  disabled={isLoading}
                >
                  {isLoading ? "Applying..." : "Apply"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <ToastContainer />
      </section>
      <div className="mt-10 pb-20">
        {" "}
        <BookAnAppointment />
      </div>
    </div>
  );
};
