import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { useAuth } from "../../Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .post("/login", { email, password })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "User successfully logged in") {
          login();
          toast.success("Login Successful");
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("id", response.data?.data?.id);
          localStorage.setItem(
            "profile_image",
            response.data?.data?.profile_image
          );
          sessionStorage.setItem("email", response.data?.data?.email);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="app">
      {/* <Header /> */}
      <ToastContainer />
      <div className="mt-2 mb-10">
        <Link to="/">
          {" "}
          <div className="sm:pl-10 sm:py-3 lg:pl-32">
            {" "}
            <img src="/new-logo.png" alt="logo" />
          </div>
        </Link>

        <div className="login">
          <h1 className="pb-4">
            <span className="decoration-[#F3B734] underline-offset-[16px] underline">
              Log{" "}
            </span>
            In
          </h1>
          <p>
            Don't have an account? Please{" "}
            <Link to="/signup" className="hover:text-[#a27a23]">
              Sign up
            </Link>
          </p>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Johndoe@gmail.com"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="pwd" className="form-label">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                    className="form-control"
                    required
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center ps-3.5 px-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col form-group">
                  <Link
                    className="plainLink small float-end "
                    to="/forgot-password"
                  >
                    <p className="hover:text-[#a27a23] text-xs">
                      Forgot Password?
                    </p>
                  </Link>
                </div>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-3"
                >
                  {isLoading ? "Logging in..." : "Login"}
                </button>
              </div>
              {/* <div className="form-link">
                <p>Or Log In with</p>
                <a href="">
                  <i className="bi bi-google"></i>
                </a>
                <a href="">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i className="bi bi-apple"></i>
                </a>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
