import React from "react";

export const WhyUs = () => {
  return (
    <section className="container whyUs  lg:px-[70px] 2xl:px-[103px]">
      <div className="flex flex-col gap-2 mb-6">
        <h2 className="text-[#F3B734] font-normal text-base">WHY CHOOSE US?</h2>
        <h3 className="text-[#212120] font-normal text-[28px] leading-[33.96px]">
          Client Satisfaction is our Priority
        </h3>
      </div>
      <div className="cardList">
        <div className="cards my-auto items-center  flex flex-col hover:bg-[#F3B734]/20">
          <h4 className="mb-4">Reliable</h4>
          <p>
            We have set systems in place to make our services reliable and
            tailored to serve you best.
          </p>
        </div>
        <div className="cards my-auto items-center  flex flex-col hover:bg-[#F3B734]/20">
          <h4 className="mb-4">Expertise</h4>
          <p>
            With years of experience, our team possesses the knowledge and
            skills to handle any logistical challenge effectively
          </p>
        </div>
        <div className="cards my-auto items-center  flex flex-col hover:bg-[#F3B734]/20">
          <h4 className="mb-4">Seamless Operations</h4>
          <p>
            From start to finish, we guarantee a smooth and efficient process,
            ensuring your goods reach their destination safely and on time.
          </p>
        </div>
        <div className="cards my-auto items-center  flex flex-col hover:bg-[#F3B734]/20">
          <h4 className="mb-4">24/7 Customer Care</h4>
          <p>
            Your satisfaction is our priority. We go above and beyond to ensure
            your needs are met and expectations exceeded.
          </p>
        </div>
      </div>
    </section>
  );
};
