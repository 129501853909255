import React from "react";
import { useState } from "react";
import { useAuth } from "../../Context";

export const StickyBar = () => {
  const { isStickyOn, offSticky } = useAuth();

  const handleSticky = () => {
    offSticky();
  };

  return (
    <section className={"position-relative " + (!isStickyOn && "stCon")}>
      <a
        href="https://wa.me/+2349067970283"
        target="_blank"
        className="stickyBar"
      >
        <i class="bi bi-whatsapp"></i>
        <p>WhatsApp Us</p>
      </a>
      <div className="cancelBar" onClick={handleSticky}>
        <i class="bi bi-x-lg"></i>
      </div>
    </section>
  );
};
