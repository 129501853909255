import React, { useState, useEffect } from "react";
import * as Components from "../Components";
import Header from "../Components/shared/header/Header";
import Footer from "../Components/shared/footer/Footer";
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';

export default function Faq() {
  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const toggleSection = (index) => {
    setOpenSectionIndex(index === openSectionIndex ? null : index);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sections = [
    {
      title: "What types of relocation services do you offer?",
      content:
        "We offer a wide range of relocation services, including interstate moves, local moves, office relocations, and day-to-day delivery solutions.",
    },
    {
      title: "How experienced is your team?",
      content:
        "Our team consists of highly skilled professionals with years of industry experience. We prioritize excellence and customer satisfaction in every relocation we undertake.",
    },
    {
      title: "How do I know which service is right for me?",
      content:
        " You can explore our range of services on our website or contact our customer service team for personalized assistance. We'll help you find the service that best fits your needs.",
    },
    {
      title:
        " How do you ensure the safety of my belongings during the relocation process?",
      content:
        "We prioritize the safety and security of your belongings at every step of the relocation process. Our team uses meticulous planning and careful handling techniques to ensure your items arrive safely at their destination.",
    },
    {
      title: "What sets your company apart from other relocation services?",
      content:
        "At our company, we pride ourselves on our commitment to excellence and customer satisfaction. We offer tailored solutions to meet the diverse needs of our clients, and our experienced team ensures a smooth and efficient relocation process every time.",
    },
    {
      title: "How can I get a quote for my relocation?",
      content:
        "You can request a quote by filling out the form on our website or contacting our customer service team directly. We'll provide you with a transparent pricing estimate based on your specific relocation needs.",
    },
    {
      title: "Do you offer packing and unpacking services?",
      content:
        "Yes, we offer comprehensive packing and unpacking services to make your relocation as seamless as possible. Our team will handle everything from packing your belongings securely to unpacking them at your new location.",
    },
    {
      title: "What areas do you serve?",
      content:
        "We primarily serve customers relocating within the state, offering both local and interstate relocation services. Contact us to see if we can assist with your specific relocation needs.",
    },
    {
      title:
        " How far in advance should I book my relocation with your company?",
      content:
        "We recommend booking your relocation with us as soon as you have your moving date confirmed. This will allow us to ensure availability and make the necessary arrangements for a smooth transition.",
    },
    {
      title: "Are your delivery services available on weekends and holidays?",
      content:
        "Yes, but incase of any changes, our clients will be properly informed.",
    },
  ];

  return (
    <div className="app">
      <Header />
      <div className="section">
        <div className="container faqs">
          <h1>Frequently Asked Questions</h1>
          <p>Questions and Answers</p>
          <div className="faqTable">
            {sections.map((section, index) => (
              <div key={index} className="question">
                <div className="questionTitle">
                  <h6>{section.title}</h6>
                  <div
                    className="question-btn"
                    onClick={() => toggleSection(index)}
                  >
                    <span>
                      <i
                        className={
                          openSectionIndex === index
                            ? "bi bi-dash-square"
                            : "bi bi-plus-square"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
                {openSectionIndex === index && (
                  <div className="questionText">
                    <p>{section.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Components.StickyBar />
      </div>
      <Footer />
    </div>
  );
}
