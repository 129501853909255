import React, { useState, useEffect } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";

export default function EmailVerification() {
  const [verification_token, setVerification_token] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(300); // 5 minutes countdown (300 seconds)
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(countdown);
          setIsTimerExpired(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(countdown);
  }, []);

  const handleVerificationTokenChange = (verification_token) => {
    setVerification_token(verification_token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (verification_token.length !== 6) {
      toast.error("Please enter a 6-digit Token sent to your email");
      return;
    }

    setIsLoading(true);
    apiClient
      .post("/verify-email", { verification_token })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Email verified successfully") {
          toast.success("Email verified successfully");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  const handleResendOTP = () => {
    // Logic to resend OTP
    navigate("/resend-otp");
    // setTimer(300); // Reset the timer to 5 minutes
    // setIsTimerExpired(false);
  };

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = time % 60;
  //   return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  // };

  return (
    <div className="app">
      <Header />
      <ToastContainer />
      <div className="section">
        <div className="login">
          <h1 className="text-center">Email Verification</h1>
          <p className=" font-normal text-base py-2 text-center">
            Enter verification code sent to ****@gmail.com
          </p>
          <div className="form justify-center">
            <form onSubmit={handleSubmit} className="pb-4">
              <div className="flex justify-center ">
                <OtpInput
                  value={verification_token}
                  onChange={handleVerificationTokenChange}
                  numInputs={6}
                  isInputNum
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 0.5rem",
                    fontSize: "1.5rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                  focusStyle={{
                    border: "1px solid #000",
                  }}
                />
              </div>
              <div className="hover:text-[#a27a23]">
                <p
                  onClick={handleResendOTP}
                  className="resend-btn pt-2.5 text-[40px] font-semibold cursor-pointer flex justify-end"
                >
                  Resend OTP
                </p>
              </div>
              <div className="form-group flex justify-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="hover:bg-[#a27a23] w-full"
                >
                  {isLoading ? "Verifying..." : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
