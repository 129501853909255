import React from 'react';
import * as Components from '../../Components';
import Header from '../../Components/shared/header/Header';
import Footer from '../../Components/shared/footer/Footer';

export default function Pack() {
  return (
    <div className="app">
      <Header />
      <div className="section">
        <Components.Pack />
        <Components.StickyBar />
      </div>
      <Footer />
    </div>
  )
}
