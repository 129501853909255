import React from "react";

const GotBookingIssues = () => {
  return (
    <section className="bg-[#A27003] flex justify-between py-[40px] sm:px-5 lg:px-36 2xl:px-[300px]">
      <div className="flex items-center">
        <p className=" uppercase text-[#FFF2D4] lg:max-w-[430px] text-sm lg:text-2xl font-medium text-left">
          Got issues BOOKING?
        </p>
      </div>
      <div className="flex items-center">
        <a href="" className="bg-[#FFFFFF] px-2.5 lg:px-4 py-2.5 rounded-md">
          <span className=" text-[#212120] text-sm font-semibold">
            <a href="tel:+2349067970283"> Contact Us</a>
          </span>
        </a>
      </div>
    </section>
  );
};

export default GotBookingIssues;
