import React, { useState } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../api/api";
import { useAuth } from "../../Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, SetPassword_confirmation] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    apiClient
      .post("/passwordReset", { email, password, password_confirmation })
      .then((response) => {
        setIsLoading(false);
        if (response.data.message === "Password reset successful") {
          toast.success("Password reset successful");

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.data) {
          toast.error(error.response.data?.message);
        } else {
          console.error(error);
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  return (
    <div className="app">
      <Header />
      <ToastContainer />
      <div className="section">
        <div className="login">
          <h1>Reset Password </h1>

          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Johndoe@gmail.com"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  value={password_confirmation}
                  onChange={(e) => SetPassword_confirmation(e.target.value)}
                  placeholder="Confirm Password"
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group d-flex justify-content-end">
                <button type="submit" disabled={isLoading}>
                  {isLoading ? "Verifying..." : "Verify"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
