import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiClient from "../../api/api";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import * as Components from "../../Components";

export const ScheduleDelivery = () => {
  const [formData, setFormData] = useState({
    pickup_address: "",
    delivery_address: "",
    sender_fullname: "",
    receiver_fullname: "",
    sender_phone: "",
    receiver_phone: "",
    pickup_date: "",
    delivery_date: "",
    description: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await apiClient.post(
        "users/schedule/delivery",
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Delivery created successfully");
      setTimeout(() => {
        setIsLoading(false);
        navigate("/thank-you");
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        Object.keys(errorData).forEach((key) => {
          errorData[key].forEach((message) => {
            toast.error(message);
          });
        });
      } else {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <section className="container Quote sm:mb-72 mt-20 lg:mb-0 lg:px-[70px]  2xl:px-[100px]">
        <ToastContainer />
        <h2 className=" font-bold text-base pb-1 pt-4 text-[#000000]">
          Plan Your Delivery
        </h2>
        <p>Schedule your delivery for the perfect time that fits your needs.</p>
        <Form className="second mt-10" onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            {[
              {
                id: "pickup_address",
                label: "Pick up Address",
                type: "text",
                placeholder: "Full address",
              },
              {
                id: "delivery_address",
                label: "Delivery Address",
                type: "text",
                placeholder: "Full address",
              },
              {
                id: "sender_fullname",
                label: "Sender’s Full Name",
                type: "text",
                placeholder: "Full name",
              },
              {
                id: "receiver_fullname",
                label: "Receiver’s Full Name",
                type: "text",
                placeholder: "Full name",
              },
              {
                id: "sender_phone",
                label: "Sender’s Number",
                type: "tel",
                placeholder: "Phone number",
              },
              {
                id: "receiver_phone",
                label: "Receiver’s Number",
                type: "tel",
                placeholder: "Phone number",
              },
              {
                id: "pickup_date",
                label: "Pick Up Date",
                type: "date",
                placeholder: "Choose pick up date",
              },
              {
                id: "delivery_date",
                label: "Delivery Date",
                type: "date",
                placeholder: "Choose delivery date",
              },
            ].map((field) => (
              <div key={field.id}>
                <label
                  htmlFor={field.id}
                  className="block mb-2 text-md font-medium text-[#212120]"
                >
                  {field.label}
                </label>
                <input
                  type={field.type}
                  id={field.id}
                  className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
                  placeholder={field.placeholder}
                  value={formData[field.id]}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
          </div>
          <div className="mb-6">
            <label
              htmlFor="description"
              className="block mb-2 text-md font-medium text-[#212120]"
            >
              Additional information
            </label>
            <textarea
              id="description"
              rows={6}
              className="bg-white border !border-[#696969] text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-4"
              placeholder="Kindly enter your package description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="pt-3 flex buttons justify-between">
            <a href="/delivery" className="btn-back">
              Back
            </a>
            <Button
              className="btn-next !py-3"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Booking..." : "Book Now"}
            </Button>
          </div>
        </Form>
        <Components.StickyBar />
      </section>
      <Footer />
    </>
  );
};
