import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Components from "../../../Components";
import BookAnAppointment from "../../appointment/bookAnAppointment";

export const Office = () => {
  return (
    <section className="">
      <section
        className={`h-[400px] flex sm:flex-col lg:flex-row bg-no-repeat bg-cover lg:h-[594px]  bg-[linear-gradient(to_right,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('/public/assets/img/office.png')] w-screen overflow-x-hidden page-enter page-enter-active 
          `}
      >
        <div className="flex flex-col items-center justify-center mx-auto sm:py-6">
          <h2 className="my-3 text-[#F3B734] font-semibold text-4xl">
            Office Relocation
          </h2>
          <div className="text-[#F5F5F5] max-w-[592px] font-semibold text-sm">
            <p>
              We specialize in making office relocations within the state easy
              and stress-free. Let's streamline your move and ensure a smooth
              transition for your team
            </p>
          </div>
          <div className="pt-10 flex sm:flex-col lg:flex-row sm:gap-5 lg:gap-5">
            <a
              href="/office-quote"
              className=" py-3 w-[200px] bg-[#F5F5F5] hover:bg-transparent hover:border hover:border-white hover:text-white rounded-[4px] "
            >
              Get a Quote
            </a>
            <a
              href="/track"
              className="py-3 hover:bg-[#F5F5F5] border border-white text-[#f5f5f5] rounded-[4px] w-[200px] hover:text-black"
            >
              Track
            </a>
          </div>
        </div>
      </section>

      <div className="bg-white lg:mx-20 mt-5 py-5 shadow-lg rounded-2xl">
        <h2 className="text-center font-medium text-4xl text-[#212120] pb-3">
          Why Choose Us
        </h2>
        <p className="text-[#212120] font-semibold text-sm text-center max-w-[790px] mx-auto">
          We understand the importance of your office furniture and ensure it
          receives the highest level of care during transportation. Our expert
          team guarantees meticulous handling, and our transparent pricing
          ensures you receive exceptional service without exceeding your budget.
        </p>
      </div>
      <Components.WhyUs />
      <div className="pb-20">
        {" "}
        <BookAnAppointment />
      </div>
    </section>
  );
};
