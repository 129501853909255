import React from "react";
import * as Components from "../../Components";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";

export default function Thankyou() {
  return (
    <div className="app">
      <Header />
      <div className=" mx-auto mt-40 sm:w-[90vw] lg:w-[900px]">
        <div className="bg-[#F3B734]">
          <h2 className="text-[#212120] font-normal text-[48px] leading-[60px] py-3 lg:text-left sm:text-center lg:pl-40">
            Thank You
          </h2>
        </div>
        <div className="bg-[#FFEABB] sm:px-8 lg:px-20 py-5 sm:rounded-none lg:rounded-br-[150px] ">
          <p className="text-[#212120] text-sm leading-[22px] pb-10 font-normal sm:text-center lg:text-left lg:pl-20">
            Thank you for choosing JVT Logistics
          </p>
          <p className="text-[#212120] text-sm leading-[22px] font-normal sm:text-center lg:text-left lg:pl-20">
            Thank you for choosing JVT Logistics. Our customer support
            representative will contact you shortly to arrange the details for
            your service. If you have opted for our relocation service, your
            tracking reference ID has been sent to your email. We look forward
            to assisting you with your logistics needs.
          </p>
          <Components.StickyBar />
        </div>
      </div>

      <Footer />
    </div>
  );
}
