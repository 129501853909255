import React from "react";

export const Store = () => {
  const sectionStyle = {
    fontFamily: "'Gilroy', sans-serif",
    padding: "20px",
    lineHeight: "1.6",
    color: "#333",
  };

  const heroStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#F5F5F5",
  };

  const heroTextStyle = {
    maxWidth: "600px",
  };

  const buttonStyle = {
    backgroundColor: "#A27A23",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "4px",
    textDecoration: "none",
    display: "inline-block",
    marginTop: "20px",
  };

  const containerStyle = {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  };

  const cardListStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  };

  const cardStyle = {
    flex: "1",
    minWidth: "280px",
    backgroundColor: "#FFF5E5",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const experienceStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    backgroundColor: "#FFF5E5",
    padding: "20px",
    borderRadius: "10px",
    marginTop: "40px",
  };

  const experienceTextStyle = {
    flex: "1",
    minWidth: "280px",
  };

  const imageStyle = {
    flex: "1",
    minWidth: "280px",
  };

  return (
    <section style={sectionStyle} className="Store">
      <div style={heroStyle}>
        <div style={heroTextStyle}>
          <h2 className=" font-bold text-5xl pb-2 pt-2 text-[#a27a23]">
            Deliver a Package
          </h2>
          <h4 className="font-medium text-lg pb-3 text-gray-900">
            Your Reliable Delivery Partner
          </h4>
          {/* <p>
            We know how crucial it is for your business to have timely
            deliveries. That's why we're here, offering top-notch delivery
            services tailored just for you. With our store delivery service,
            your products will get to your customers quickly and safely, leaving
            a great impression every time.
          </p> */}
          <div className="button">
            <a href="/store-quote" style={buttonStyle} className="">
              Get Started
            </a>
          </div>
        </div>
      </div>
      <div style={containerStyle} className="how_work container">
        <h2>How it Works</h2>
        <div style={cardListStyle} className="cardList">
          <div style={cardStyle} className="cards">
            <h4>Schedule Your Deliveries</h4>
            <p>
              Simply log in to our platform and schedule your deliveries
              according to your business's needs. Whether it's a one-time
              delivery or a recurring delivery, we've got you covered.
            </p>
          </div>
          <div style={cardStyle} className="cards">
            <h4>Pickup & Packaging</h4>
            <p>
              Our experienced couriers will pick up your packages from your
              location at the scheduled time. Each package is carefully
              processed and labeled for efficient transit.
            </p>
          </div>
          <div style={cardStyle} className="cards">
            <h4>Timely Delivery</h4>
            <p>
              Your packages are delivered promptly according to the agreed-upon
              schedule. With real-time tracking and updates, you'll know exactly
              when your delivery will arrive.
            </p>
          </div>
        </div>
        <div style={experienceStyle} className="Experience">
          <div style={imageStyle} className="image">
            {/* <img src="./assets/img/bike.png" alt="" /> */}
            <img
              src="./assets/img/delivery.jpg"
              alt=""
              style={{ width: "100%", borderRadius: "500px" }}
            />
          </div>
          <div style={experienceTextStyle} className="text">
            <h3>Experience Seamlesss Deliveries with JVT Logistics</h3>
            <p>
              Join the countless businesses who rely on JVT Logistics for their
              delivery needs. With our commitment to excellence and unparalleled
              customer service, we're here to support your business every step
              of the way. Streamline your operations and elevate your delivery
              experience with JVT Logistics
            </p>
          </div>
          <div
            style={{ ...imageStyle, display: "none" }}
            className="image hide"
          >
            <img src="./assets/img/bus.png" alt="" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </section>
  );
};
