import React, { useState, useEffect } from "react";
import BookAnAppointment from "../appointment/bookAnAppointment";

export const About = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const containerStyle = {
    fontFamily: "'Gilroy', sans-serif",
    padding: "20px",
    lineHeight: "1.6",
    color: "#333",
    maxWidth: "1200px",
    margin: "0 auto",
  };

  const headerStyle = {
    textAlign: "center",
    color: "#A27A23",
    marginBottom: "20px",
    fontWeight: "bold",
  };

  const introStyle = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "40px",
    flexWrap: isMobile ? "wrap" : "nowrap",
  };

  const imageStyle = {
    flex: isMobile ? "100%" : "1",
    borderRadius: "10px",
    overflow: "hidden",
    width: "100%",
    marginBottom: isMobile ? "20px" : "0",
  };

  const textStyle = {
    flex: isMobile ? "100%" : "2",
    padding: "20px",
    backgroundColor: "#FFF5E5",
    borderRadius: "10px",
  };

  const sectionStyle = {
    marginBottom: "40px",
    padding: "20px",
    backgroundColor: "#FFF5E5",
    borderRadius: "10px",
  };

  const titleStyle = {
    color: "#A27A23",
    marginBottom: "10px",
    fontWeight: "bold",
    fontSize: isMobile ? "1.2rem" : "1.5rem",
  };

  const paragraphStyle = {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "'Gilroy medium', sans-serif",
    fontWeight: "bold",
    lineHeight: "22px",
  };

  return (
    <section className="w-full">
      <div className="flex flex-col lg:flex-row w-full p-2 lg:p-5 lg:mt-20 gap-10 lg:gap-12 2xl:gap-60 text-left lg:mx-auto lg:justify-center">
        <div className="max-w-[700px]">
          <h2 className=" text-[#A27A23] font-semibold text-4xl  mb-4 sm:text-center lg:text-left">
            About Us
          </h2>
          <p className=" text-[#343432] font-semibold text-sm max-w-[500px] mx-auto sm:text-center lg:text-justify  pb-3">
            At JVT Logistics, we redefine efficiency and reliability in the
            realm of transportation and relocation services. Specializing in
            interstate relocation, local relocation, and day-to-day delivery
            solutions, we are your trusted partner for seamless logistical
            operations.
          </p>
          <p className=" text-[#343432] font-semibold text-sm max-w-[500px] mx-auto sm:text-center lg:text-justify  pb-3">
            Our team of dedicated professionals is committed to ensuring that
            your items are handled with care and delivered on time, every time.
            Whether you're moving homes, businesses, or need to manage routine
            deliveries, we offer customized solutions tailored to meet your
            unique needs.
          </p>
          <p className=" text-[#343432] font-semibold text-sm max-w-[500px] mx-auto sm:text-center lg:text-justify  pb-3">
            {" "}
            With cutting-edge technology and a customer-centric approach, we
            strive to make every move smooth and stress-free. Choose JVT
            Logistics for unparalleled service and a hassle-free experience from
            start to finish.
          </p>

          {/* <div className="sm:mt-5 sm:flex sm:justify-center lg:justify-normal lg:mt-10">
            <a
              href=""
              className="py-6 px-24 bg-[#F3B734] text-[#212120] font-semibold rounded"
            >
              Explore Services
            </a>
          </div> */}
        </div>
        <div className="rounded-lg md:mx-auto lg:mx-0 ">
          <img src="./image 30.png" alt="about-us" className="rounded-lg" />
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row-reverse  w-full py-5 px-2 lg:p-5 lg:my-10 lg:gap-24 lg:justify-center 2xl:gap-72    text-left">
        <div className="w-auto">
          <h2 className=" text-[#F3B734] font-bold text-base  mb-3 sm:text-center mx-auto lg:text-left sm:pt-5 lg:pt-0">
            WHO WE ARE
          </h2>

          <p className=" text-[#343432] font-medium text-sm  max-w-[560px] mx-auto pb-3 text-justify">
            At JVT Logistics, we redefine efficiency and reliability in the
            realm of transportation and relocation services. Specializing in
            interstate relocation, local moves, and day-to-day delivery
            solutions, we are your trusted partner for seamless logistical
            operations.
          </p>
          <p className=" text-[#343432] font-medium text-sm max-w-[560px] mx-auto pb-3 text-justify">
            At the heart of JVT Logistics lies a commitment to excellence and
            customer satisfaction. With years of industry experience, we have
            honed our expertise to offer tailored solutions that meet the
            diverse needs of our clients.
          </p>
          <p className=" text-[#343432] font-medium text-sm max-w-[560px] mx-auto text-justify">
            Our dedicated team of professionals possesses the skills and
            knowledge necessary to execute smooth and efficient relocation,
            whether it's across state lines or within your local community. From
            meticulous planning to timely execution, we prioritize the safety
            and security of your belongings every step of the way.
          </p>
        </div>
        <div className="w-auto md:mx-auto lg:mx-0 ">
          <img src="./image 31.png" alt="about-us" className="" />
        </div>
      </div>
      <section className="bg-[#E9E7E2] w-full px-2 sm:px-0 sm:py-5   flex flex-col lg:py-[60px]">
        <div className="flex flex-col lg:flex-row gap-6 justify-center ">
          <div className="bg-[#F3B734] p-6 max-w-[460px] sm:mx-auto lg:mx-0  rounded-lg">
            <div className="flex gap-3 text-left mb-4 items-center ">
              <h2 className=" font-medium text-[28px] leading-[33.96px] text-[#212120]">
                Our Vision
              </h2>
            </div>
            <p className=" text-[#343432] font-semibold text-sm text-justify">
              To become the leading and most reliable delivery and relocation
              company in Africa.
            </p>
          </div>
          <div className="bg-[#F3B734] p-6 max-w-[455px] sm:mx-auto lg:mx-0 rounded-lg">
            <div className="flex gap-3 text-left mb-4 items-center ">
              <h2 className=" font-medium text-[28px] leading-[33.96px] text-[#212120]">
                Our Mission
              </h2>
            </div>
            <p className=" text-[#343432] font-semibold text-sm text-justify">
              Our mission is to provide a seamless innovative technology
              solution that connects customers with reliable logistics service
              providers, empowering businesses and individuals to efficiently
              manage their supply chains. We aim to optimize operations, enhance
              transparency and deliver exceptional value to our clients while
              driving growth and sustainability in the logistics ecosystem.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};
