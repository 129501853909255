import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { ProgressBar } from "react-bootstrap";
import "./PackageDetails.css";

export default function PackageDetails() {
  const location = useLocation();
  const { referenceNumber, status, progressBar , dateCreated, dateUpdated, serviceType} =
    location.state || {};

  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options).replace(/,/, "");
  };

  const isActive = (currentStatus, checkStatus) => {
    const statusOrder = ["ToInspect", "Shipped", "Delivered"];
    return (
      statusOrder.indexOf(currentStatus) >= statusOrder.indexOf(checkStatus)
    );
  };

  return (
    <div className="w-full">
      <Header />
      <div className="text-center mt-36">
        <h1 className="font-normal text-5xl text-[#A27A23] pb-16">
          Track Your Move
        </h1>
      </div>
      <div className="lg:w-[505px] mx-auto bg-[#F3B734] rounded-[16px]">
        <div className="p-6">
          <div>
            <p className="text-[#212120] font-normal text-base pb-3">
              Tracking ID: #{referenceNumber}
            </p>
            <p className="text-[#212120] font-normal text-base pb-3">
              Service Type: {serviceType}
            </p>
            <div className="pb-3">
              <ProgressBar animated variant="warning" now={(progressBar / 100) * 100} />
            </div>

            <div className="timeline">
              <div
                className={`timeline-item ${
                  isActive(status, "To Inspect") ? "active" : ""
                }`}
              >
                <div className="timeline-icon"></div>
                <div className="timeline-content">
                  <p className="status">To Inspect</p>
                  <p className="date">
                    {dateCreated ? formatDate(dateCreated) : "---:---"}
                  </p>
                </div>
              </div>
              <div
                className={`timeline-item ${
                  isActive(status, "Shipped") ? "active" : ""
                }`}
              >
                <div className="timeline-icon"></div>
                <div className="timeline-content">
                  <p className="status">Shipped</p>
                  <p className="date">
                    {isActive(status, "Shipped")
                      ? formatDate(dateUpdated)
                      : "---:---"}
                  </p>
                </div>
              </div>
              <div
                className={`timeline-item ${
                  isActive(status, "Delivered") ? "active" : ""
                }`}
              >
                <div className="timeline-icon"></div>
                <div className="timeline-content">
                  <p className="status">Delivered</p>
                  <p className="date">
                    {isActive(status, "Delivered")
                      ? formatDate(dateUpdated)
                      : "---:---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
