import React from "react";

const OurRelocationServices = () => {
  return (
    <section id="relocation-services" className="my-14">
      <h3 className=" text-[#212120] text-[40px] leading-[49px] font-semibold text-center pb-6">
        Our Relocation Services
      </h3>
      <div className="mx-auto flex flex-col items-center lg:flex-row gap-8 lg:justify-center lg:px-24">
        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-6 px-7">
          <div>
            <img src="./assets/img/iconOffice.png" alt="office-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-3">
            Office Relocation
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-[38px] text-center">
            We specialize in making office relocations within the state easy and
            stress-free. Let's streamline your move and ensure a smooth
            transition for your team
          </p>
          <a
            href="/office"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-3 rounded-md"
          >
            Book Now
          </a>
        </div>

        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-6 px-7">
          <div>
            <img src="./assets/img/iconLocal.png" alt="local-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-3">
            Local Relocation
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-[57px] text-center">
            Your hassle-free solution for moving within the state! Let's make
            your relocation journey smooth and stress-free.
          </p>
          <a
            href="/local"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-3 rounded-md"
          >
            Book Now
          </a>
        </div>

        <div className="border !border-[#F3B734] rounded-2xl w-[347px] h-[322px] flex flex-col items-center py-6 px-7">
          <div>
            <img src="./assets/img/iconState.png" alt="interstate-relocation" />
          </div>
          <h5 className=" text-[#212120] font-semibold text-xl pb-3">
            Inter-State Relocation
          </h5>
          <p className=" text-[#212120] font-semibold text-sm pb-3 text-center">
            We offer comprehensive moving services for homes and offices. From
            Abuja to all the 35 states, our expert movers ensure a smooth
            transition, making your relocation stress-free.
          </p>
          <a
            href="/interstate"
            className="text-[#212120] font-semibold text-lg bg-[#F3B734] hover:bg-[#a27a23] px-7 py-3 rounded-md"
          >
            Book Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default OurRelocationServices;
