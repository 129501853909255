import React from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context";

const Profile = () => {
  const { isAuthenticated, login, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <Header />
      <div className="flex mt-36 lg:mx-24 gap-2 sm:mx-2 lg:gap-5 2xl:px-[200px]">
        <div className="bg-[#FFEABB] h-[70vh] w-[250px] flex flex-col justify-between items-center pt-2">
          <div>
            {" "}
            <h3 className=" font-semibold lg:text-2xl text-[#212120] pb-3">
              <Link to={"/profile-update"}>Profile</Link>
            </h3>
            <p className="text-[#F3B734] font-semibold lg:text-2xl">
              <Link to={"/profile"}>Account</Link>
            </p>
          </div>

          <div className="pb-3">
            <p className="font-semibold lg:text-2xl text-[#212120] bg-[#F3B734] py-2 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a] ">
              <Link className="getstarted" onClick={handleLogout}>
                Log Out
              </Link>
            </p>
          </div>
        </div>
        <div className="bg-[#F5F5F5] sm:w-full lg:h-[70vh] lg:w-[60vw] p-3  lg:p-6">
          <div>
            {" "}
            <h3 className=" font-semibold text-2xl text-[#212120] pb-9">
              Account
            </h3>
            <div className="flex justify-between lg:items-center flex-col lg:flex-row ">
              <div className="text-[#212120] space-y-2">
                <h6 className=" font-medium text-base">
                  <>
                    <b>Profile Information</b>
                  </>
                </h6>
                <p className="font-medium text-base">
                  Change the password for the account
                </p>
              </div>
              <div className="pt-4 ">
                <Link
                  to={"/change-password"}
                  fix-on-geting-the-service-type
                  className=" font-normal lg:text-lg text-[#212120] bg-[#F3B734] py-3 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a]"
                >
                  Change password
                </Link>
              </div>
            </div>
            <div className="flex justify-between lg:items-center pt-10 flex-col lg:flex-row  ">
              <div className="text-[#212120] space-y-2">
                <h6 className=" font-medium text-base">
                  <>
                    <b>Delete account</b>
                  </>
                </h6>
                <p className="font-medium text-base">
                  Permanently delete account
                </p>
              </div>
              <div className="pt-4">
                <Link
                  to={"/deactivate-account"}
                  fix-on-geting-the-service-type
                  className="font-normal lg:text-lg text-[#212120] bg-[#F3B734] py-3 px-3 rounded-md text-sm cursor-pointer hover:bg-[#d29f2a]"
                >
                  Delete account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
