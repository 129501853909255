import React, { useRef } from "react";

const WhatOurCustomersSay = () => {
  const scrollContainerRef = useRef(null);

  // Function to scroll left
  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  // Function to scroll right
  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  return (
    <section className="relative bg-white sm:px-5 lg:px-[160px] py-4 pt-9 pb-20 2xl:px-[300px]">
      <h2 className="text-center w-[200px] text-black font-medium text-2xl mx-auto pb-7">
        What our <span className="font-bold text-[#A27A23]">customers say</span>
      </h2>

      {/* Left Arrow */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-[60%] transform -translate-y-[50%] bg-[#A27A23] text-white px-3 lg:mx-[100px] py-2 rounded-full shadow-md"
      >
        &#8249;
      </button>

      <div
        ref={scrollContainerRef}
        className="flex gap-10 overflow-x-auto scroll-smooth"
      >
        <div className="text-justify w-[330px] tracking-tighter shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT Logistics has made our deliveries seamless and precise. Their
            prompt service has improved our supply chain. Highly recommended!
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">
            Ocean Crest Beauty Palace
          </p>
        </div>
        <div className="text-justify w-[330px] tracking-tight shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT handles our sensitive products with care and ensures timely
            deliveries. Their customer service is exceptional.
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">
            Pskincare Store
          </p>
        </div>
        <div className="text-justify w-[330px] tracking-tight shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT Logistics improved our delivery process, with no more late or
            damaged goods. Their service is excellent.
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">
            Damis Haven Store
          </p>
        </div>
        <div className="text-justify w-[330px] tracking-tight shrink-0">
          <p className="pb-2 text-[#212120]  text-base">
            JVT provides timely, reliable deliveries of our dental supplies,
            keeping our clinic running smoothly.
          </p>
          <p className="text-[#F3B734] font-semibold text-lg">
            Blanche Dental Care
          </p>
        </div>
      </div>

      {/* Right Arrow */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-[60%] transform -translate-y-[50%] bg-[#A27A23] text-white px-3 py-2 lg:mx-[100px] rounded-full shadow-md"
      >
        &#8250;
      </button>
    </section>
  );
};

export default WhatOurCustomersSay;
