import React, { useEffect } from "react";
import Header from "../../Components/shared/header/Header";
import Footer from "../../Components/shared/footer/Footer";
import OurRelocationServices from "../../Components/relocation/OurRelocationServices";
import GotBookingIssues from "../../Components/relocation/GotBookingIssues";
import WhyMoveWithUs from "../../Components/relocation/WhyMoveWithUs";
import WhatOurCustomersSay from "../../Components/relocation/WhatOurCustomersSay";
import RelocationHeroSection from "../../Components/relocation/RelocationHeroSection";

const Relocation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="h-full w-full">
      <section className="lg:mb-28">
        <Header />
      </section>
      <RelocationHeroSection />
      <OurRelocationServices />
      <WhyMoveWithUs />
      <WhatOurCustomersSay />
      <GotBookingIssues />
      <Footer />
    </div>
  );
};

export default Relocation;
